import { createTheme } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';

export const pxToRem = (value: number) => {
  return `${value / 16}rem`;
};

export const fontBase: CSSProperties = {
  fontSize: pxToRem(12),
  fontFamily: 'NotoSans',
  lineHeight: pxToRem(18),
  letterSpacing: 'inherit',
  color: '#171414',
};

const fontSm: CSSProperties = {
  fontSize: pxToRem(10),
  fontFamily: 'NotoSans',
  lineHeight: pxToRem(16),
  letterSpacing: 'inherit',
  color: '#171414',
};

const fontH5: CSSProperties = {
  fontSize: pxToRem(20),
  fontFamily: 'Ubuntu',
  lineHeight: pxToRem(26),
  letterSpacing: 'inherit',
  color: '#171414',
};
const fontH6: CSSProperties = {
  fontSize: pxToRem(18),
  fontFamily: 'Ubuntu',
  lineHeight: pxToRem(26),
  letterSpacing: 'inherit',
  color: '#171414',
};
const fontH7: CSSProperties = {
  fontSize: pxToRem(16),
  fontFamily: 'Ubuntu',
  lineHeight: pxToRem(22),
  letterSpacing: 'inherit',
  color: '#171414',
};
const fontH8: CSSProperties = {
  fontSize: pxToRem(14),
  fontFamily: 'Ubuntu',
  lineHeight: pxToRem(20),
  letterSpacing: 'inherit',
  color: '#171414',
};

const theme = createTheme({
  typography: {
    body1: fontBase,
    body2: fontSm,
    h1: fontH5,
    h2: fontH6,
    h3: fontH7,
    h4: fontH8,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: fontBase.fontSize,
          fontFamily: fontH5.fontFamily,
          lineHeight: fontBase.lineHeight,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: fontH8.fontSize,
          fontFamily: fontH8.fontFamily,
          lineHeight: fontH8.lineHeight,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: fontBase.fontSize,
          fontFamily: fontBase.fontFamily,
          lineHeight: fontBase.lineHeight,
          letterSpacing: 'inherit',
          fontWeight: 'normal',
          textTransform: 'none',
          height: pxToRem(40),
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(16),
          fontFamily: 'Ubuntu',
          lineHeight: pxToRem(22),
          letterSpacing: 'inherit',
          color: '#171414',
        },
      },
    },
  },
});

export default theme;
