import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import remove from 'lodash/remove';
import { DispenseWeight, FGG } from '../common/types';
import { mergeEachMemberWithObjectClone } from '../common/utils';
import { extractVectorFeedSettings } from '../components/Vector/util';
import { RootState } from './rootReducer';
import {
  AppliedPropertiesInitialState,
  FeedPropertyTypes,
} from './types/appliedPropertiesTypes';
import { setVectorDataChart } from './vectorDataChartSlice';

export const initialState: AppliedPropertiesInitialState = {
  allTimeSelected: null,
  feedProperties: null,
  eventProperties: null,
  areAllSubMenusClosed: false,
};

export const appliedPropertiesSlice = createSlice({
  name: 'appliedProperties',
  initialState,
  reducers: {
    setFeedProperties: (state, action: PayloadAction<FeedPropertyTypes>) => {
      state.allTimeSelected = setAllTimeSelected(
        action.payload,
        state.allTimeSelected,
      );

      state.feedProperties = action.payload;
    },
    resetAppliedProperties: () => initialState,
    setAreAllSubMenusClosed: (state, action: PayloadAction<boolean>) => {
      state.areAllSubMenusClosed = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(
      setVectorDataChart,
      // TODO: Newer typescript from Vector Response
      (state, action: PayloadAction<any>) => {
        const vectorNewFeedHeightSettingsData = extractVectorFeedSettings(
          action.payload.chartData.settings.otherSettings,
        );

        mergeEachMemberWithObjectClone(
          vectorNewFeedHeightSettingsData,
          {
            selected: false,
            parentClassName: 'bb-mfrline',
            itemClassName: 'bb-eventline',
          },
          state.allTimeSelected,
        );

        state.feedProperties =
          vectorNewFeedHeightSettingsData as FeedPropertyTypes;
      },
    ),
});

export const {
  resetAppliedProperties,
  setFeedProperties,
  setAreAllSubMenusClosed,
} = appliedPropertiesSlice.actions;

// selectors
export const appliedPropertiesSelectors = {
  selectAppliedFeedProperties: (state: RootState) =>
    state.appliedProperties.feedProperties,
  // TODO: add the event properties into redux
  selectAppliedEventProperties: (state: RootState) =>
    state.appliedProperties.eventProperties,
  selectAreAllSubMenusClosed: (state: RootState) =>
    state.appliedProperties.areAllSubMenusClosed,
};

export const setAllTimeSelected = (
  feedProps: FeedPropertyTypes | null,
  latestAllTimeSelectedFromState: any,
) => {
  if (feedProps) {
    const allTimeSelected = {};
    Object.keys(feedProps).forEach((key) => {
      if (feedProps[key]) {
        // key could be "Feed height"
        // feedProps[key] could be the "Feed height" object that looks something like this: { members: { Droog2: {...}, Mekkoe2: {...}, etc.}}
        const allFeedIdsForCategory: string[] = [];

        for (const currentFeedName in feedProps[key].members) {
          const currentFeedId = feedProps[key].members[currentFeedName].id;
          allFeedIdsForCategory.push(currentFeedId);
        }
        if (
          latestAllTimeSelectedFromState &&
          latestAllTimeSelectedFromState[key]
        ) {
          remove(latestAllTimeSelectedFromState[key], (feedId: string) => {
            return allFeedIdsForCategory.includes(feedId);
          });
        }

        const selectedNames: string[] = [];
        Object.keys(feedProps[key].members).forEach((name: string) => {
          if (feedProps[key].members[name].selected === true) {
            selectedNames.push(feedProps[key].members[name].id);
          }
        });

        allTimeSelected[key] = [
          ...(latestAllTimeSelectedFromState &&
          key !== FGG &&
          key !== DispenseWeight
            ? latestAllTimeSelectedFromState[key]
            : []),
          ...selectedNames,
        ];
      }
    });

    return allTimeSelected;
  }

  return null;
};

export default appliedPropertiesSlice.reducer;
