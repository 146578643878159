import { ApisWithFarmName } from '../../api/lelyBackend/config';
import { LOCAL_STORAGE_KEYS } from '../../common/constants';
import { getSearchParams, updateSearchParams } from '../../common/utils';

export const getFarmNameParams = (request) => {
  let farmId = getSearchParams('farmId');

  const shouldAddFarmName = ApisWithFarmName.find((item) => {
    return request.url?.match(item);
  });

  if (!farmId) {
    farmId = localStorage.getObject(LOCAL_STORAGE_KEYS.FARM_ID);
    if (farmId && shouldAddFarmName) updateSearchParams('farmId', farmId, true);
  } else {
    localStorage.setObject(LOCAL_STORAGE_KEYS.FARM_ID, farmId);
  }

  return { shouldAddFarmName, farmId };
};

export default getFarmNameParams;
