import createSagaMiddleware from 'redux-saga';
import { fromZonedTime } from 'date-fns-tz';
import omit from 'lodash/omit';
// Redux persist
import { persistStore, createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import rootSaga from './sagas/rootSaga';
import rootReducer from './reducers/rootReducer';
import {
  GET_CHART_EVENTS_REQUEST,
  UPDATE_DATA_CHART_REQUESTED,
} from './components/DataCharts/types/dataChartTypes';
import { selectFarmTimeZone } from './reducers/entityInformationReducer';
import {
  REQUEST_GET_UPDATE_HISTORY_DATA,
  REQUEST_GET_UPDATE_HISTORY_DATA_FOR_SYSTEM,
} from './components/UpdatesHistory/types/updateHistoryTypes';
import {
  requestCompositeStateUpdate,
  updateMilkJarWeight,
} from './reducers/compositeStatesSlice';

const blacklistPaths = [
  'userSession.loginError',
  'redirect',
  'eventPageDetails',
  'eventsPageLevels',
  'entityDetails',
  'entitySettings',
  'entitySettingsOptions',
  'updateEntityName',
  'alarmsLevels',
  'eventsLevels',
  'eventsLevelMenuOption',
  'graph',
  'loadManager',
  'logDetails', // not persisted because its size is too big and exceeds local storage size quota
  'entityBackupSettings',
  'entityBackupSettings.restoreStatusMessage',
  'entityBackupSettings.backUpButtons',
  'entityChartDetails',
  'entityKpi',
  'dataCharts',
  'farmDetails',
  'vectorDataCharts',
  'featureFlag',
  'eventsChartData',
  'vectorDetails',
  'farmsOptions',
  'zoom',
  'vectorCriticalAlarms',
  'astronautPropertySelect',
  'vectorCriticalAlarmsAndEvents',
  'tooltip',
  'lineChartConfig',
  'colorDictionary',
  'canvasGeneration',
  'graphElements',
  'kpi',
  'interceptors',
  'aggregation',
  'logs',
  'farms',
];

// add all action types for which startDate or startDate and endDate should be transformed in UTC format
const ACTIONS_TO_TRANSFORM = [
  updateMilkJarWeight.type,
  requestCompositeStateUpdate.type,
  GET_CHART_EVENTS_REQUEST,
  UPDATE_DATA_CHART_REQUESTED,
  'logs/getLogNodes',
  'logs/getLogContent',
  'logs/downloadLogFiles',
  'logs/getModalLogNodes',
  REQUEST_GET_UPDATE_HISTORY_DATA,
  REQUEST_GET_UPDATE_HISTORY_DATA_FOR_SYSTEM,
  'GET_ALARMS_FOR_ENTITY_OVERVIEW_REQUEST',
  'GET_UPDATES_REQUEST',
];

const dateTransformMiddleware = (store) => (next) => (action) => {
  if (ACTIONS_TO_TRANSFORM.includes(action.type)) {
    const state = store.getState();
    const farmTimeZone = selectFarmTimeZone(state);

    if (
      action.payload &&
      (action.payload.startDate || action.payload.fromDate)
    ) {
      const transformedPayload = {
        ...action.payload,
      };

      if (action.payload.startDate) {
        transformedPayload.startDate = fromZonedTime(
          new Date(action.payload.startDate),
          farmTimeZone,
        ).toISOString();
      }

      if (action.payload.fromDate) {
        transformedPayload.fromDate = fromZonedTime(
          new Date(action.payload.fromDate),
          farmTimeZone,
        ).toISOString();
      }

      // Only transform endDate if it exists
      if (action.payload.endDate) {
        transformedPayload.endDate = fromZonedTime(
          new Date(action.payload.endDate),
          farmTimeZone,
        ).toISOString();
      }

      if (action.payload.toDate) {
        transformedPayload.toDate = fromZonedTime(
          new Date(action.payload.toDate),
          farmTimeZone,
        ).toISOString();
      }

      return next({ ...action, payload: transformedPayload });
    }

    if (action.payload && action.payload.toDate) {
      const transformedPayload = {
        ...action.payload,
        toDate: fromZonedTime(
          new Date(action.payload.toDate),
          farmTimeZone,
        ).toISOString(),
      };

      return next({ ...action, payload: transformedPayload });
    }
  }
  return next(action);
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: blacklistPaths,
  transforms: [
    // nested blacklist-paths require a custom transform to be applied
    createTransform((inboundState: any, key) => {
      const reducerName = String(key);
      const blackListForKey = blacklistPaths
        .filter((path) => path.startsWith(`${reducerName}.`))
        .map((path) => path.substring(reducerName.length + 1));
      return omit(inboundState, ...blackListForKey);
    }, null),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [dateTransformMiddleware, sagaMiddleware];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: false,
    }).concat(middlewares),
  devTools: {
    trace: true,
  },
});
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
