import { AxiosError, AxiosResponse } from 'axios';
import { UserRolesType } from '../../components/LoginForm/types';
import { AuthResponse } from '../lelyBackend/loginApi';

export const MOCK_VALID_USERNAME = 'LelyAdmin';
export const MOCK_VALID_PASSWORD = 'password';
export const MOCK_PRELOADER_STATUS = true;
export const MOCK_PART_ID = 'PCB';
export const MOCK_MODAL_LAYOUT_DATA = 'mock modal layout data';
export const MOCK_SYSTEM_ID = '51e2f58c-4c98-eb11-b566-000d3a221d55';
export const MOCK_VALID_ACCESS_TOKEN = 'access_token';
export const MOCK_VALID_REFRESH_TOKEN = 'refresh_token';
export const MOCK_LOGIN_SUCCESS_RESPONSE: AxiosResponse<AuthResponse> = {
  data: {
    accessToken: MOCK_VALID_ACCESS_TOKEN,
    refreshToken: MOCK_VALID_REFRESH_TOKEN,
    userRoles: [UserRolesType.Engineer, UserRolesType.Admin],
    userLanguage: 'en',
  },
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {} as any,
};

export const MOCK_LOGIN_ERROR = 'error during login';
export const MOCK_LOGIN_ERROR_NEW: AxiosError = {
  isAxiosError: true,
  toJSON: async () => 'asd',
  message: 'error during login',
  name: 'error',
};

export const MOCK_LOGOUT_ERROR = 'error during logout';
export const MOCK_ON_ERROR = 'Error during operation';
export const MOCK_REDIRECT = {
  destination: '/farms',
  search: '',
  body: { farmId: 2 },
};
export const MOCK_START_DATE_TO_TIMESTAMP = '2019-12-18T13:50:46';
export const MOCK_END_DATE_TO_TIMESTAMP = '2019-12-19T14:16:16';
export const MOCK_SELECTED_TYPE_NAMES = ['Warning', 'Alarm'];
export const MOCK_APPLICATION_VERSION = '1.11.0-0';
export const MOCK_LATEST_TIMESTAMP_BUILD = '1606946982340';
export const IS_PRELOADER_ACTIVE = { isActive: 'on' };
