import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';

// eslint-disable-next-line react/jsx-props-no-spreading
const SettingTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: 'none',
  fontWeight: 300,
  minHeight: 40,
  '&.Mui-selected': {
    fontWeight: 700,
  },
}));

function SettingsTabs() {
  const navigate = useNavigate();
  const { externalId } = useParams();
  const [value, setValue] = useState(0);
  const [isAMF, setIsAMF] = useState(false);
  useEffect(() => {
    if (window.location.pathname.includes('backups')) {
      setValue(1);
    } else if (window.location.pathname.includes('amf')) {
      setIsAMF(true);
    } else if (window.location.pathname.includes('settings')) {
      setValue(0);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function redirectOnClick(page) {
    const urlExternalId = externalId || window.location.pathname.split('/')[2];
    navigate(`/entities/${urlExternalId}/${page}`);
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row' }}
      data-testid="navbar-container"
    >
      <Box width="100%" className="tabs-container">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="inherit"
          sx={{ textTransform: 'none' }}
        >
          <SettingTab
            onClick={() =>
              redirectOnClick(!isAMF ? 'astronaut/settings' : 'amf/settings')
            }
            label="Settings"
          />
          {!isAMF && (
            <SettingTab
              onClick={() => redirectOnClick('astronaut/settings/backups')}
              label="Backups"
            />
          )}
        </Tabs>
      </Box>
    </div>
  );
}
export default SettingsTabs;
