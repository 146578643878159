export const getParentClassnameForEvent = (
  eventName,
  categoryName,
  mfrType,
) => {
  return (
    `bb-${removeSpaces(categoryName)}-${removeSpaces(eventName)} ` +
    `event-${mfrType}`
  );
};

export const removeSpaces = (specificString) => {
  return specificString.split(' ').join('');
};

export const getUuid = () => {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
};

let lastEventStripRangeStartDate = {};
export const setLastEventStripRangeStartDate = (
  newLastEventStripRangeStartDate,
) => {
  lastEventStripRangeStartDate = newLastEventStripRangeStartDate;
};

export const getLastEventStripRangeStartDate = () => {
  return lastEventStripRangeStartDate;
};

let lastEventStripRangeEndDate = {};
export const setLastEventStripRangeEndDate = (
  newLastEventStripRangeEndDate,
) => {
  lastEventStripRangeEndDate = newLastEventStripRangeEndDate;
};

export const getLastEventStripRangeEndDate = () => {
  return lastEventStripRangeEndDate;
};
