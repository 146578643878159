import { put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { Farm, VectorKpi } from './types';
import {
  setFarms,
  setIsFarmError,
  setKpisLoading,
  setKpisLoaded,
  updateFarmsWithKpis,
} from '../../reducers/farmsSlice';
import { api } from '../../api/lelyBackend/farmsApi';

export function* farmsSaga() {
  try {
    const response: AxiosResponse<Farm[]> = yield call(api.getFarms);
    yield put(setFarms(response.data));
  } catch (error: any) {
    yield put(setIsFarmError(error));
  }
}

export function* fetchKpisSaga() {
  try {
    yield put(setKpisLoading(true));
    const response: AxiosResponse<
      {
        id: string;
        dailyVectorKpi: VectorKpi[];
        version: string;
      }[]
    > = yield call(api.getFarmsKpis);
    yield put(updateFarmsWithKpis(response.data));
    yield put(setKpisLoaded(true));
    yield put(setKpisLoading(false));
  } catch (error: any) {
    yield put(setIsFarmError(error));
  } finally {
    yield put(setKpisLoading(false));
  }
}
