import {
  GET_ENTITY_INFO_REQUEST,
  GET_ENTITY_INFO_REQUEST_SUCCESS,
  GET_ENTITY_INFO_REQUEST_ERROR,
  RESET_ENTITY_INFO,
  RESET_FARM_NAME_IN_BREADCRUMBS_COMPONENT,
} from './types';

/** Create action which to get all information for current entity .
 * @param {String} selectedEntityId - the id of the entity for which we want to get the data
 * @return {Object} Return type and info for current entity
 */
export function getEntityInfo(selectedEntityId) {
  return {
    type: GET_ENTITY_INFO_REQUEST,
    payload: {
      entityId: selectedEntityId,
    },
  };
}

/** Create action which to allow to put entity data in the redux store
 * @param {Object} entityInfo - the data for the selected entity
 * @param {string} requestUuid - the ID of the request. We are going to compare this to the latest
 * request ID to the last one that was made for this type of data.
 * We will skip using this data if this id is different than the last one
 * for this data type.
 * @return {Object} Return type and entity info (name,entityId,farmName,farmId)
 */
export function getEntityInfoSuccess(entityInfo, requestUuid) {
  return {
    type: GET_ENTITY_INFO_REQUEST_SUCCESS,
    payload: { ...entityInfo, requestUuid },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getEntityInfoError(errorMessage) {
  return {
    type: GET_ENTITY_INFO_REQUEST_ERROR,
    payload: errorMessage,
  };
}

export function resetEntityInfo() {
  return {
    type: RESET_ENTITY_INFO,
  };
}

export function resetFarmNameInBreadcrumbsComponent() {
  return {
    type: RESET_FARM_NAME_IN_BREADCRUMBS_COMPONENT,
  };
}
