/* istanbul ignore file */

/** Initialize is preloader active state */

import { store } from '../../../configureStore';

const translate = (languageValue) => {
  if (languageValue) {
    let languageTranslations = null;
    try {
      const { preferredLanguage } = store.getState().translate;
      languageTranslations =
        // eslint-disable-next-line import/no-dynamic-require, global-require
        require(`./Languages/${preferredLanguage}`).default;
    } catch (error) {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      languageTranslations = require(`./Languages/en`).default;
    }

    return languageTranslations[languageValue];
  }
};

export default translate;
