import { Button, ButtonProps, styled } from '@mui/material';

interface PrimaryButtonProps extends ButtonProps {
  'data-testid'?: string;
}

const PrimaryButton = styled(Button)<PrimaryButtonProps>({
  backgroundColor: '#c30a14',
  fontFamily: 'Ubuntu',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#B30015',
  },
  '&:disabled': {
    backgroundColor: '#efedea',
    color: '#766e68;',
  },
});

export default PrimaryButton;
