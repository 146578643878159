import cloneDeep from 'lodash/cloneDeep';
import {
  SHOW_MODAL_FOR_DOMAIN_PART,
  HIDE_MODAL_FOR_DOMAIN_PART,
  UPDATE_MODAL_LAYOUT_DATA,
  MINIMIZE_MODAL_FOR_DOMAIN_PART,
  CLOSE_ALL_OPENED_MODALS,
  RESTORE_MODAL_FOR_DOMAIN_PART,
} from '../components/DomainModalGrid/types';
import { TROUBLESHOOTER_POPUP_TITLE } from '../common/constants';

/** Store states
 * @constant {Object} */
export const initialState = {
  canbusModalData: [],
};

/** Domain Modal Grid Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function domainModalGridReducer(state = initialState, action = {}) {
  const partId = action.payload && action.payload.partId;

  let newModalData = cloneDeep(state.canbusModalData);

  switch (action.type) {
    case SHOW_MODAL_FOR_DOMAIN_PART: {
      let isThereAPopupShownAlreadyForThisKey = false;
      newModalData.forEach((val) => {
        if (val.i === partId) {
          isThereAPopupShownAlreadyForThisKey = true;
        }
      });
      if (isThereAPopupShownAlreadyForThisKey) {
        return {
          ...state,
        };
      }
      if (partId !== TROUBLESHOOTER_POPUP_TITLE) {
        newModalData.push({ i: partId, x: 8, y: 0, w: 4, h: 8 });
      } else {
        newModalData.push({ i: partId, x: 6, y: 0, w: 6, h: 17 }); // show bigger popup if user opened Troubleshooter
      }
      return {
        ...state,
        canbusModalData: newModalData,
      };
    }
    case HIDE_MODAL_FOR_DOMAIN_PART:
      newModalData = newModalData.filter((val) => {
        return val.i !== partId;
      });
      return {
        ...state,
        canbusModalData: newModalData,
      };

    case MINIMIZE_MODAL_FOR_DOMAIN_PART:
      // eslint-disable-next-line array-callback-return
      newModalData = newModalData.map((val) => {
        if (val.i === partId) {
          return {
            ...val,
            h: 1,
          };
        }
      });
      return {
        ...state,
        canbusModalData: newModalData,
      };

    // eslint-disable-next-line no-duplicate-case
    case RESTORE_MODAL_FOR_DOMAIN_PART:
      // eslint-disable-next-line array-callback-return
      newModalData = newModalData.map((val) => {
        if (val.i === partId) {
          return {
            ...val,
            h: 3,
          };
        }
      });
      return {
        ...state,
        canbusModalData: newModalData,
      };

    case UPDATE_MODAL_LAYOUT_DATA:
      newModalData = action.payload.newLayoutData;
      return {
        ...state,
        canbusModalData: newModalData,
      };

    case CLOSE_ALL_OPENED_MODALS:
      return {
        ...state,
        canbusModalData: [],
      };
    default:
      return state;
  }
}

export default domainModalGridReducer;
