import { put, call } from 'redux-saga/effects';
import { getEntityInfoError, getEntityInfoSuccess } from './actions';
import {
  setStartLoading,
  setEndLoading,
} from '../../redux/loadManager/actions';
import { getLoadId } from '../../redux/loadManager/methods';
import { requestDictionary } from '../../requestUtil';
import { GET_ENTITY_INFO_SERVICE_URL } from '../../../api/lelyBackend/config';
import { getUuid } from '../../../components/Vector/mfrchart/Util';

let currentLoadId = 0;

export default function* getEntityInformation(api, action) {
  try {
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));

    const { entityId } = action.payload;

    // #region requestUtil logic
    const requestUuid = getUuid();
    requestDictionary[GET_ENTITY_INFO_SERVICE_URL] = requestUuid;
    // #endregion

    const entityInfoData = yield call(api.getEntityInfo, entityId);
    yield put(
      getEntityInfoSuccess(
        {
          entityInfoData: entityInfoData.data,
        },
        requestUuid,
      ),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getEntityInfoError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
