/* istanbul ignore file */

import cloneDeep from 'lodash/cloneDeep';
import {
  DATA_KEY_CHARTS_DATA_RECEIVED,
  DATA_KEY_CHARTS_DATA_ERROR,
  BRUSH_ON_BOTTOM_CHART_DETECTED,
  RESET_DOMAIN,
  RESET_CHARTS_DATA,
  WHEEL_ZOOM_ONGOING_STATUS_CHANGE,
  INIT_ZOOM_CHART,
} from '../components/DataCharts/types/dataChartTypes';
import { getFilteredGraphConstituents } from '../components/AstronautDataExplorer/util/CustomZoomDomainFiltering';

/** Store states
 * @constant {Object} */
export const initialState = {
  chartsData: {},
  isDataChartsError: false,
  errorMessage: '',
};

/** Data Chart Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function dataChartReducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_ZOOM_CHART:
      return {
        ...state,
        zoomChartDataKey: action.payload.zoomChartDataKey,
        doesZoomChartRequireRequestToBackend:
          action.payload.doesZoomChartRequireRequestToBackend,
      };
    /** Action Creator - Set data chart to store if
     *  its respective API request passed successfully */
    case DATA_KEY_CHARTS_DATA_RECEIVED: {
      const { chartId, chartData, startDate, endDate } = action.payload;
      const newState = cloneDeep(state);
      newState.chartsData[chartId] = chartData;
      newState.isDataChartsError = false;
      newState.errorMessage = '';
      newState.currentDomain = [new Date(startDate), new Date(endDate)];
      chartData.forEach((currentGraphInfo) => {
        if (currentGraphInfo.type === 'status') {
          const statusChartColorDictionary = {};
          currentGraphInfo.graphs[0].colors.forEach((currentColor) => {
            statusChartColorDictionary[currentColor.value] = currentColor.hex;
          });
          currentGraphInfo.graphs[0].colors = statusChartColorDictionary;
          const statusChartActivities = currentGraphInfo.graphs[0].axesData;
          currentGraphInfo.graphs[0].axesData = statusChartActivities.map(
            (currentStatusActivity, currentStatusActivityIndex) => {
              const nextActivityIndex =
                currentStatusActivityIndex === statusChartActivities.length - 1
                  ? undefined
                  : currentStatusActivityIndex + 1;
              return {
                x: new Date(currentStatusActivity.x),
                time: new Date(currentStatusActivity.x),
                name: currentStatusActivity.y,
                endTime: nextActivityIndex
                  ? new Date(statusChartActivities[nextActivityIndex].x)
                  : undefined,
              };
            },
          );
        } else {
          // else it is a line chart
          const graphConstituents = getFilteredGraphConstituents(
            currentGraphInfo.graphs[0].axesData,
            [new Date(startDate), new Date(endDate)],
            true,
          );
          currentGraphInfo.graphDates = graphConstituents.graphDates;
          currentGraphInfo.graphValues = graphConstituents.graphValues;
          currentGraphInfo.lineTrendStartValue =
            graphConstituents.lineTrendStartValue;
        }
      });
      return newState;
    }

    case DATA_KEY_CHARTS_DATA_ERROR:
      return {
        ...state,
        chartsData: {},
        isDataChartsError: true,
        errorMessage: action.payload,
      };

    case BRUSH_ON_BOTTOM_CHART_DETECTED:
      return {
        ...state,
        currentDomain: action.payload,
      };

    case WHEEL_ZOOM_ONGOING_STATUS_CHANGE:
      return {
        ...state,
        wasInitiatedByChart: action.payload.isWheelZoomOngoing,
      };

    case RESET_DOMAIN:
      return {
        ...state,
        currentDomain: undefined,
      };

    case RESET_CHARTS_DATA:
      return {
        ...state,
        chartsData: {},
      };

    default:
      return state;
  }
}

export default dataChartReducer;
