import isEmpty from 'lodash/isEmpty';
import {
  FEED_GRABBER_DUMPING_COLOR,
  FEED_GRABBER_GRABBING_COLOR,
  FEED_GRABBER_SCANNING_COLOR,
} from '../../../common/constants';
import {
  getActivitiesFromFeedGrabberObject,
  getActivitiesFromMfrChartObject,
  getActivityColorsConfigDictionaryFromMfrChartObject,
  getEventsFromMfrChartObject,
  getActivitiesFromPdbObject,
} from '../../../common/data/vector_chart_data_modifier';
import {
  setFgItemsData,
  setMfr1ItemsData,
  setMfr2ItemsData,
  setPdbItemsData,
} from '../events_logic';

// #region Creating the vector chart config object
export const getVectorChartConfigObject = (
  fullSetOfVectorSettings,
  vectorChartsData,
  latestAppliedProperties,
  eventTypeToCategoryDictionary,
) => {
  if (!isEmpty(vectorChartsData)) {
    const mfr1ActivitiesData = getActivitiesFromMfrChartObject(
      vectorChartsData,
      'mfr1',
      (currentActivity) => {
        return currentActivity.name !== 'Loading';
      },
    );
    const mfr2ActivitiesData = getActivitiesFromMfrChartObject(
      vectorChartsData,
      'mfr2',
      (currentActivity) => {
        return currentActivity.name !== 'Loading';
      },
    );
    const mfr1LoadingActivitiesData = getActivitiesFromMfrChartObject(
      vectorChartsData,
      'mfr1',
      (currentActivity) => {
        return currentActivity.name === 'Loading';
      },
    );
    const mfr2LoadingActivitiesData = getActivitiesFromMfrChartObject(
      vectorChartsData,
      'mfr2',
      (currentActivity) => {
        return currentActivity.name === 'Loading';
      },
    );
    const {
      differentThanGrabActivities,
      grabActivities,
      grabSubactivities,
      craneMovingActivities,
      feedHeightActivities,
      feedHeightMaxWeight,
    } = getActivitiesFromFeedGrabberObject(
      vectorChartsData,
      fullSetOfVectorSettings,
    );
    const pdbActivities = getActivitiesFromPdbObject(vectorChartsData);
    const mfr1ItemsData = getEventsFromMfrChartObject(
      latestAppliedProperties,
      eventTypeToCategoryDictionary,
      vectorChartsData,
      'mfr1',
    );
    const mfr2ItemsData = getEventsFromMfrChartObject(
      latestAppliedProperties,
      eventTypeToCategoryDictionary,
      vectorChartsData,
      'mfr2',
    );
    const fgItemsData = getEventsFromMfrChartObject(
      latestAppliedProperties,
      eventTypeToCategoryDictionary,
      vectorChartsData,
      'fg',
    );
    const pdbItemsData = getEventsFromMfrChartObject(
      latestAppliedProperties,
      eventTypeToCategoryDictionary,
      vectorChartsData,
      'pdb',
    );

    const activityColorsConfigDictionary =
      getActivityColorsConfigDictionaryFromMfrChartObject(vectorChartsData);
    setMfr1ItemsData(mfr1ItemsData);
    setMfr2ItemsData(mfr2ItemsData);
    setFgItemsData(fgItemsData);
    setPdbItemsData(pdbItemsData);

    const { feedIdToColorDictionary } = fullSetOfVectorSettings;
    const isThereMfr2ForCurrentRobot =
      mfr2ActivitiesData && mfr2ActivitiesData.length > 0;
    return {
      mfr1Activities: mfr1ActivitiesData,
      mfr2Activities: mfr2ActivitiesData,
      mfr1LoadingActivities: mfr1LoadingActivitiesData,
      mfr2LoadingActivities: mfr2LoadingActivitiesData,
      activityColors: activityColorsConfigDictionary,
      mfr1ItemsData,
      mfr2ItemsData,
      fgItemsData,
      pdbItemsData,
      eventsData: latestAppliedProperties,
      grabActivities,
      grabSubactivities,
      feedGrabberActivities: differentThanGrabActivities,
      activityHeightPropertyName: 'loadedWeight',
      craneMovingActivities,
      pdbActivities,
      feedHeightActivities,
      feedHeightMaxWeight,
      feedIdToColorDictionary,
      startTimePropertyName: 'time',
      craneMovingColor: '#005C5C',
      feedGrabberGrabScanningColor: FEED_GRABBER_SCANNING_COLOR,
      feedGrabberGrabGrabbingColor: FEED_GRABBER_GRABBING_COLOR['Normal grab'],
      feedGrabberGrabDumpingColor: FEED_GRABBER_DUMPING_COLOR,
      feedGrabberNormalGrabColor: '#24A148',
      feedGrabberGrabWithRetriesColor: '#0D5F26',
      feedGrabberGrabFromMultipleStorageColor: '#70DD8D',
      loadingActivityGradientGreenColor: '#24A148',
      loadingActivityGradientBrownColor: '#5F4500',
      loadingActivityMissingFeedColor: '#70DD8D',
      loadingActivityExceedingFeedColor: '#0D5F26',
      defaultEventWidthInPixels: 14,
      defaultEventHeightInPixels: 14,
      isThereMFR2: isThereMfr2ForCurrentRobot,
    };
  }
};

/** Ratio according to screen height 1080 / measurementsForNewDesignInPixels[key]
 * example: MFR1_TOP_Y: 54 -> 1080 / 54 = 20px on screen (Xw x 1080h) where X could be 1920
 */

// below when I enter comment // I want you to change the value to 1080 / value
export const measurementsForNewDesignRatio = {
  // MFR1
  MFR1_EVENTS_BASELINE_Y: 0, // 1080 / 0 = Infinity
  MFR1_TOP_Y: 54, // 1080 / 54 = 20px
  MFR1_ACTIVITIES_BASELINE_Y: 0.00001,
  MFR1_ACTIVITIES_BASELINE_ABSOLUTE_Y: 54, // 1080 / 54 = 20px
  MFR1_ACTIVITIES_HEIGHT: 14.31052631578947, // 1080 / 14.31052631578947 = 75px
  // mfr1 events - top: 0px, height: 20px;
  // mfr1 activities - top: 20px, height: 75px;

  // FG
  FG_EVENTS_BASELINE_Y: 10, // 1080 / 10 = 108px
  FG_TOP_Y: 8.4375, // 1080 / 8.4375 = 128px
  GRAB_TASK_FEED_HEIGHT_BASELINE_Y: 0.00002,
  // GRAB_TASK_FEED_HEIGHT_BASELINE_ABSOLUTE_Y: 9.23076923076923, // 1080 / 9.23076923076923 = 117px
  GRAB_TASK_FEED_HEIGHT_BASELINE_ABSOLUTE_Y: 8.4375, // 1080 / 8.4375 = 128px
  GRAB_TASK_FEED_HEIGHT_HEIGHT: 24, // 1080 / 32.75 = 45px
  GRAB_CONTAINER_BASELINE_ABSOLUTE_Y: 6.27906976744186, // top 128 + 45 height = 172 -> 1080 / 6.27906976744186 = 172px
  GRAB_CONTAINER_BASELINE_Y: 24, // 1080 / 32.75 = 45px
  GRAB_CONTAINER_HEIGHT: 27, // 1080 / 27 = 40px
  GRAB_CONTAINER_SUBTASK_BASELINE_ABSOLUTE_Y: 5.625, // 1080 / 5.625 = 192px - grab's activities sitting on top of the grab container
  GRAB_CONTAINER_SUBTASK_BASELINE_Y: 16.6153846, // 1080 / 16.6153846 = 65px
  GRAB_CONTAINER_SUBTASK_HEIGHT: 54, // 1080 / 54 = 20px
  CRANE_MOVING_CONTAINER_BASELINE_ABSOLUTE_Y: 5.094339622641509, // 1080 / 5.3465346 = 212px
  CRANE_MOVING_CONTAINER_BASELINE_Y: 12.70588235294118, // 1080/12.70588235294118 = 85px
  CRANE_MOVING_CONTAINER_HEIGHT: 54, // 1080 / 54 = 20px

  // PDB
  PDB_BASELINE_ABSOLUTE_Y: 4.4628099, // 1080 / 5.454545454545455 = 242px
  PDB_BASELINE_Y: 0.00003,
  PDB_TOP_Y: 4.4628099, // 1080 / 5.0943396226 = 222px
  PDB_HEIGHT: 54, // 1080 / 54 = 20px
  PDB_COLOR_BASELINE_ABSOLUTE_Y: 4.122137, // 1080 / 4.976958525345622 = 262px
  PDB_COLOR_BASELINE_Y: 54, // 1080 / 54 = 20px
  PDB_COLOR_HEIGHT: 54, // 1080 / 54 = 20px
  PDB_EVENTS_BASELINE_Y: 3.829787234042553, // 1080 / 4.32 = 282px
  PDB_EVENTS_HEIGHT: 54, // 1080 / 54 = 20px

  // MFR2
  MFR2_TOP_Y: 3.354037267080745, // 1080 / 3.354037267080745 = 322px
  MFR2_ACTIVITIES_BASELINE_ABSOLUTE_Y: 3.354037267080745, // 1080 / 3.354037267080745 = 322px
  MFR2_ACTIVITIES_BASELINE_Y: 0.00004,
  MFR2_ACTIVITIES_HEIGHT: 14.31052631578947, // 1080 / 14.31052631578947 = 75px
  MFR2_EVENTS_BASELINE_Y: 2.720403022670025, // 1080 / 3.103448275862069 = 397px
  MFR2_EVENTS_HEIGHT: 30, // 1080 / 30 = 36px

  // CONSTANTS
  FOUR_PIXELS: 270, // 1080 / 270 = 4px
  TEN_PIXELS: 108, // 1080 / 108 = 10px
  DEFAULT_ACTIVITY_HEIGHT_IN_PIXELS: 24, // 1080 / 27.69230769230769 = 45px
  MAX_ACTIVITY_HEIGHT_IN_PIXELS: 14.31052631578947, // 1080 / 13.5 = 80px
};

/**
 * In theory these keys from measurementsForNewDesignRatio represents 0 pixels,
 * but since their values are used as keys in the click map and should be unique
 * they represents numbers like 0.0001, 0.0002, 0.0003 instead of 0.
 */
const keysOfAbsoluteValuesNotRatios = [
  'MFR1_ACTIVITIES_BASELINE_Y',
  'GRAB_TASK_FEED_HEIGHT_BASELINE_Y',
  'PDB_BASELINE_Y',
  'MFR2_ACTIVITIES_BASELINE_Y',
];

export const updateMeasurementsBasedOnWindowInnerHeight = () => {
  const windowInnerHeight = window.innerHeight;

  const measurementConfiguration = {};
  Object.keys(measurementsForNewDesignRatio).forEach((key) => {
    measurementConfiguration[key] = keysOfAbsoluteValuesNotRatios.includes(key)
      ? measurementsForNewDesignRatio[key]
      : windowInnerHeight / measurementsForNewDesignRatio[key];
  });
  return measurementConfiguration;
};

export const DEFAULT_MFR2_EVENTS_Y_IN_PX_RATIO =
  measurementsForNewDesignRatio.MFR2_TOP_Y;

export const FEED_GRABBER_SUBTASKS_Y =
  measurementsForNewDesignRatio.GRAB_CONTAINER_SUBTASK_BASELINE_ABSOLUTE_Y;
export const FG_TOP_Y = measurementsForNewDesignRatio.FG_TOP_Y;
export const FEED_GRABBER_SUBTASKS_HEIGHT =
  measurementsForNewDesignRatio.GRAB_CONTAINER_SUBTASK_HEIGHT;

export const DEFAULT_MFR2_EVENTS_Y_OFFSET_IN_PX = 10;
export const ZOOM_LEVEL_AFTER_WHICH_TO_SHOW_GRAB_TASK_STROKES = 16;
export const MARGIN_AND_SPACING_ON_THE_LEFT_OF_MFR_CHART_IN_PX = 35;
