import moment from 'moment';
import 'moment-timezone';

export const formatTimeForTimeout = (timeArary) => {
  let result = '';
  if (timeArary[0] !== '00') {
    result = `${timeArary[0]} h`;
  } else if (timeArary[1] !== '00') {
    result += `${timeArary[1]} min`;
  } else if (timeArary[2] !== '00') {
    result += `${timeArary[2]} sec`;
  }
  return result;
};

export const initialBackupSteps = (timeForTimeout) => {
  return [
    'Initializing',
    `Waiting for response\n(max ${timeForTimeout})`,
    'Backup finished',
  ];
};

export const finalBackupSteps = (
  createdTimeFormated,
  timeForTimeout,
  finishedTimeFormated,
  creationStatusReason,
  status,
) => {
  const finalStep =
    status === 'successful'
      ? `Backup finished\n${finishedTimeFormated}\n${'Successful: Settings backup completed successfully'}`
      : `Backup failed\n${finishedTimeFormated}\n${creationStatusReason}`;

  return [
    `Initializing\n${createdTimeFormated}\nSuccessful: Request is send`,
    `Waiting for response\n${createdTimeFormated}\nDuration: max. ${timeForTimeout}`,
    finalStep,
  ];
};

export const failedSteps = (timeFormated, errorMessage, timeForTimeout) => {
  const result = [
    `Initializing\n${timeFormated ? `${timeFormated}\n` : ''}${
      errorMessage ? `${errorMessage}\n` : ''
    }`,
    `Waiting for response\n(max ${timeForTimeout})`,
    'Backup finished',
  ];
  return result;
};

/** This method generates the progress of a Restore,
 *  when the robot is offline and the restore is not started
 */
export const failedToSentSteps = (
  steps: string[],
  timeForTimeout: string,
  errorMessage: string,
) => {
  const result = steps.map((step, index) => {
    if (index === 0) {
      return `${step}\n${timeForTimeout}\n${errorMessage}`;
    }
    return step;
  });
  return result;
};

export const sentSteps = (timeFormated, timeForTimeout) => {
  return [
    `Initializing${
      timeFormated ? `\n${timeFormated}` : ''
    }\nSuccessful: Request is send`,
    `Waiting for response\n${
      timeFormated ? `${timeFormated}\n` : ''
    }Duration: max. ${timeForTimeout}`,
    'Backup finished',
  ];
};

export const sentRestoreSteps = (prevSteps, timeFormated) => {
  const result = [...prevSteps];
  result.splice(
    0,
    1,
    `Initializing${
      timeFormated ? `\n${timeFormated}` : ''
    }\nSuccessful: Request is send`,
  );
  result.splice(
    1,
    1,
    `Waiting for response\n${
      timeFormated ? `${timeFormated}\n` : ''
    }Duration: max. 20min. User action is required. Accept the restore request on the robot user interface.`,
  );
  return result;
};

export const initialRestoreSteps = [
  'Initializing',
  'Waiting for response\nDuration: max. 20 min. User action is required. Accept the restore request on the robot user interface.',
  `Restore finished`,
];

export const finishedRestoreSteps = (prevSteps, timeFinished, reason) => {
  const result = [...prevSteps];
  result.splice(2, 1, `Restore finished\n${timeFinished}\n${reason}`);
  return result;
};

export const calculateTimeDiff = (
  timeSpanToValidate,
  formattedStatusTimestamp,
  farmTimeZone,
) => {
  const timeToValidate = timeSpanToValidate?.split(':');
  const timeForTimeout = moment(formattedStatusTimestamp)
    .add(timeToValidate[0], 'h')
    .add(timeToValidate[1], 'm')
    .add(timeToValidate[2], 's')
    .toString()
    .split(' GMT')[0];
  const now = moment().tz(farmTimeZone).toString().split(' GMT')[0];
  const remainingTime =
    new Date(timeForTimeout).getTime() - new Date(now).getTime();
  return { remainingTime, timeForTimeout };
};

export const doesBackupTimeout = (
  formattedTimestampCreated: string | null | undefined,
  formattedTimestampFinished: string | null | undefined,
) => {
  if (!formattedTimestampCreated && !formattedTimestampFinished) {
    return false;
  }
  const start = moment(formattedTimestampCreated);
  const end = moment(formattedTimestampFinished);
  const duration = moment.duration(end.diff(start));
  const minutes = duration.asMinutes();

  return minutes === 20;
};
