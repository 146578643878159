import moment from 'moment';
import { addAndReturnDateInTFormat } from '../../common/domains';

export const DATE_RANGE_HOUR = 'Hour';
export const DATE_RANGE_DAY = 'Day';
export const DATE_RANGE_WEEK = 'Week';

export const URL_PARAM_NAME = 'range';

// #region Range configuration
export const RANGES_CONFIG = {};
RANGES_CONFIG[DATE_RANGE_HOUR] = {
  hours: 1,
  hoursIncrement: 0.25,
  label: DATE_RANGE_HOUR,
};
RANGES_CONFIG[DATE_RANGE_DAY] = {
  hours: 24,
  hoursIncrement: 3,
  label: DATE_RANGE_DAY,
};
RANGES_CONFIG[DATE_RANGE_WEEK] = {
  hours: 168,
  hoursIncrement: 24,
  label: DATE_RANGE_WEEK,
};
// #endregion

export const getMomentOfDatePlusHours = (date, hoursToIncrement) => {
  return moment(addAndReturnDateInTFormat(date, hoursToIncrement)).toDate();
};

export const getEndDateByStartDateAndRange = (
  startDate,
  selectedRangeObject,
) => {
  const selectedRangeDurationInHours = selectedRangeObject.hours;
  return getMomentOfDatePlusHours(startDate, selectedRangeDurationInHours);
};
