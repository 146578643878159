export const getRangeDurationInMilliseconds = (
  rangeStartDate,
  rangeEndDate,
) => {
  return rangeEndDate.getTime() - rangeStartDate.getTime();
};

export const getRangeDurationInSeconds = (rangeStartDate, rangeEndDate) => {
  return getRangeDurationInMilliseconds(rangeStartDate, rangeEndDate) / 1000;
};

export const getOverlapRangeInSecondsBasedOnCurrentZoom = (
  eventIconWidthInPixels,
  timelineWidthInPixels,
  zoomStartDate,
  zoomEndDate,
) => {
  const iconToTimelineWidthRatio =
    eventIconWidthInPixels / timelineWidthInPixels;
  const durationInSecondsOfCurrentZoom = getRangeDurationInSeconds(
    zoomStartDate,
    zoomEndDate,
  );
  return iconToTimelineWidthRatio * durationInSecondsOfCurrentZoom;
};
