import axiosInstance from '../axiosInstance';
import {
  GET_LOGS_FOR_ENTITY_AND_HOUR_SERVICE_URL,
  GET_LOG_NODES_URL,
  GET_LOG_CONTENT_URL,
  LOG_FILENAME_APPENDIX,
  DOWNLOAD_LOGS_URL,
  GET_MODAL_LOG_NODES_URL,
  END_TIME_URL_APPENDIX,
  START_TIME_URL_APPENDIX,
} from './config';

export const api = {
  async getLogsForEntityAndHour(entityId: string, date: string) {
    const getLogsForEntityAndHourServiceUrl =
      GET_LOGS_FOR_ENTITY_AND_HOUR_SERVICE_URL +
      entityId +
      START_TIME_URL_APPENDIX +
      date;

    return axiosInstance.get(getLogsForEntityAndHourServiceUrl);
  },
  async getLogNodes(entityId: string, logDateTime: string) {
    const getLogNodesUrl =
      GET_LOG_NODES_URL + entityId + START_TIME_URL_APPENDIX + logDateTime;

    return axiosInstance.get<string[]>(getLogNodesUrl);
  },
  async getModalLogNodes(
    entityId: string,
    logStartDateTime: string,
    logEndDateTime: string,
  ) {
    const getModalLogNodesUrl =
      GET_MODAL_LOG_NODES_URL +
      entityId +
      START_TIME_URL_APPENDIX +
      logStartDateTime +
      END_TIME_URL_APPENDIX +
      logEndDateTime;

    return axiosInstance.get<string[]>(getModalLogNodesUrl);
  },

  async downloadLogNodes(
    entityId: string,
    startTime: string,
    endTime: string,
    selectedNodesForDownload: string[],
  ) {
    let logNodes = '';
    selectedNodesForDownload.forEach((node) => {
      logNodes += `&fileNames=${node}`;
    });

    const getLogNodesUrl = `${
      DOWNLOAD_LOGS_URL + entityId
    }&startTime=${startTime}&endTime=${endTime}${logNodes}`;

    return axiosInstance.get<string>(getLogNodesUrl);
  },
  async getLogContent(
    entityId: string,
    logFileName: string,
    logDateTime: string,
  ) {
    const getLogContentUrl =
      GET_LOG_CONTENT_URL +
      entityId +
      START_TIME_URL_APPENDIX +
      logDateTime +
      LOG_FILENAME_APPENDIX +
      logFileName;

    return axiosInstance.get<string>(getLogContentUrl);
  },
};

export type LogsApiType = typeof api;
