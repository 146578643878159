import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { UI_DATE_FORMAT } from '../../../common/constants';
import { GraphAxesData } from '../../../reducers/types/vectorDataTypes';

export const isOnlyStatusChartsSelected = (chartsData, chartId) => {
  let areOnlyStatusChartsSelected = true;
  chartsData[chartId].forEach((currentChart) => {
    if (currentChart.type === 'line' || currentChart.type === 'spline') {
      areOnlyStatusChartsSelected = false;
    }
  });
  return areOnlyStatusChartsSelected ? ' is-hidden-milkChart' : '';
};

function getFirstItemBeforeRange(array, date) {
  let lastItemBeforeRange;
  array.forEach((currentItem, currentItemIndex) => {
    if (moment(date).isAfter(new Date(currentItem.x))) {
      lastItemBeforeRange = array[currentItemIndex];
    }
    return false;
  });
  return lastItemBeforeRange;
}

function filterArrayBasedOnZoomDomain(array, zoomDomain, isZoomEnabled) {
  const filteredArray = array.filter((axisItem) => {
    if (
      isZoomEnabled &&
      zoomDomain &&
      zoomDomain[0] &&
      zoomDomain[1] &&
      zoomDomain[0].toString() !== zoomDomain[1].toString()
    ) {
      const isCurrentItemInDateRange = moment(new Date(axisItem.x)).isBetween(
        new Date(zoomDomain[0]),
        new Date(zoomDomain[1]),
        null,
        '[]',
      );
      if (isCurrentItemInDateRange) {
        return axisItem;
      }
    } else {
      return axisItem;
    }
    return false;
  });

  const firstItemBeforeRange = getFirstItemBeforeRange(
    array,
    new Date(zoomDomain[0]),
  );
  const isFirstItemBeforeRangeinDateRange = moment(
    new Date(firstItemBeforeRange?.endTime),
  ).isBetween(new Date(zoomDomain[0]), new Date(zoomDomain[1]), null, '[]');

  if (isFirstItemBeforeRangeinDateRange) {
    filteredArray.push(firstItemBeforeRange);
  }

  if (filteredArray.length <= 1) {
    const currentStatusStart = getFirstItemBeforeRange(
      array,
      new Date(zoomDomain[0]),
    );
    if (currentStatusStart) {
      currentStatusStart.x = moment(new Date(zoomDomain[0]))
        .add(moment.duration(5, 'seconds'))
        .format(UI_DATE_FORMAT);
      if (filteredArray.length === 0) {
        filteredArray.push(currentStatusStart);
      }
    }
  }
  if (zoomDomain && zoomDomain[1]) {
    const currentStatusEnd = {
      x: moment(new Date(zoomDomain[1]))
        .subtract(moment.duration(1, 'seconds'))
        .format(UI_DATE_FORMAT),
      y: 'NULL',
    };
    filteredArray.push(currentStatusEnd);
  }
  return filteredArray;
}

export const getSingleGraphDataWithFilteredValues = (
  singleGraphData,
  currentDomain,
  zoomEnabled,
) => {
  const arrayToFilter = singleGraphData.axesData
    ? singleGraphData.axesData
    : singleGraphData.graphs[0].axesData;
  const filteredAxisData = filterArrayBasedOnZoomDomain(
    arrayToFilter,
    currentDomain,
    zoomEnabled,
  );
  const singleGraphDataCopy = cloneDeep(singleGraphData);
  singleGraphDataCopy.axesData = filteredAxisData;
  return singleGraphDataCopy;
};

export const getFilteredGraphConstituents = (
  axesData: GraphAxesData[],
  filterByDateExtent: Date[],
  zoomEnabled: boolean,
) => {
  const graphDates: Date[] = [];
  const graphValues: number[] = [];
  const filteredItems = filterArrayBasedOnZoomDomain(
    axesData,
    filterByDateExtent,
    zoomEnabled,
  );

  filteredItems.forEach((axisItem) => {
    if (axisItem.y !== 'No Data') {
      if (axisItem && axisItem.x) {
        graphDates.push(new Date(axisItem.x));
      } else {
        graphDates.push(axisItem.x);
      }
      graphValues.push(parseFloat(axisItem.y));
    }
  });
  return {
    graphDates,
    graphValues,
  };
};

export const getFilteredGraphDatesByZoomDomain = (
  singleGraphData,
  currentDomain,
  zoomEnabled,
) => {
  const graphDates = filterArrayBasedOnZoomDomain(
    singleGraphData.axesData,
    currentDomain,
    zoomEnabled,
  )
    .filter((axisItem) => axisItem.y !== 'No Data')
    .map((axisItem) => {
      if (axisItem && axisItem.x) {
        return new Date(axisItem.x);
      }
      return axisItem.x;
    });
  return graphDates;
};

export const getFilteredGraphValuesByZoomDomain = (
  singleGraphData,
  currentDomain,
  zoomEnabled,
) => {
  return filterArrayBasedOnZoomDomain(
    singleGraphData.axesData,
    currentDomain,
    zoomEnabled,
  )
    .filter((axisItem) => axisItem.y !== 'No Data')
    .map((axisItem) => {
      return parseFloat(axisItem.y);
    });
};
