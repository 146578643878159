import { AvailableLanguages } from '../../reducers/types/translationTypes';
import axiosInstance from '../axiosInstance';
import { UPDATE_LANGUAGE, GET_LANGUAGES } from './config';

export const MOCK_UPDATE_LANGUAGE_RESPONSE = {
  data: {
    language: 'en',
  },
};

export const MOCK_GET_ALL_LANGUAGES_RESPONSE = {
  data: [{ code: 'en', Language: 'English' }],
};
export const api = {
  async updateLelyPreferredLanguage(language) {
    const languageData = { languageCode: language };
    return axiosInstance.put(UPDATE_LANGUAGE, languageData);
  },

  async getLanguages() {
    return axiosInstance.get<AvailableLanguages[]>(GET_LANGUAGES);
  },
};
