import { put, call } from 'redux-saga/effects';
import { getFarmDetailsSuccess, getFarmDetailsError } from './actions';
import {
  setStartLoading,
  setEndLoading,
} from '../../common/redux/loadManager/actions';
import { getLoadId } from '../../common/redux/loadManager/methods';
import { GET_FARM_ENTITIES_SERVICE_URL } from '../../api/lelyBackend/config';
import { getUuid } from '../Vector/mfrchart/Util';
import { requestDictionary } from '../../common/requestUtil';

let currentLoadId = 0;

export default function* handleFarmDetails(api, action) {
  try {
    currentLoadId = getLoadId();
    yield put(setStartLoading(currentLoadId));

    const { farmId, farmName } = action.payload;

    // #region requestUtil logic
    const requestUuid = getUuid();
    requestDictionary[GET_FARM_ENTITIES_SERVICE_URL] = requestUuid;
    // #endregion

    const farmDetails = yield call(api.getFarmEntity, farmId);
    const { entities, farmTimeZone } = farmDetails.data;

    yield put(
      getFarmDetailsSuccess(
        {
          farmId,
          farmEntities: entities,
          farmName,
          farmTimeZone,
        },
        requestUuid,
      ),
    );

    yield put(setEndLoading(currentLoadId));
  } catch (error) {
    yield put(getFarmDetailsError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
