/* istanbul ignore file */

import {
  GET_CHARTS_CONFIGURATION_REQUEST_SUCCESS,
  GET_CHARTS_CONFIGURATION_REQUEST_ERROR,
  GET_CHARTS_LINKED_CONFIGURATION_REQUEST_SUCCESS,
} from '../components/DataCharts/types/dataChartTypes';

/** Store states
 * @constant {Object} */
export const initialState = {
  configChartsData: {},
  configLinkedChartsData: {},
  isDataConfigChartsError: true,
  errorMessage: '',
};

/** Data Chart Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function dataConfigChartReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set data chart to store if
     *  its respective API request passed successfully */
    case GET_CHARTS_CONFIGURATION_REQUEST_SUCCESS:
      return {
        ...state,
        configChartsData: action.payload,
        isDataConfigChartsError: false,
        errorMessage: '',
      };

    case GET_CHARTS_LINKED_CONFIGURATION_REQUEST_SUCCESS:
      return {
        ...state,
        configLinkedChartsData: action.payload,
        isDataConfigChartsError: false,
        errorMessage: '',
      };

    case GET_CHARTS_CONFIGURATION_REQUEST_ERROR:
      return {
        ...state,
        configChartsData: {},
        isDataConfigChartsError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
}

export const selectDataChartConfig = (state) => {
  if (
    state.dataConfigCharts.configChartsData &&
    state.dataConfigCharts.configChartsData.charts &&
    state.dataConfigCharts.configChartsData.charts.configurations
  ) {
    return state.dataConfigCharts.configChartsData.charts.configurations;
  }
  return null;
};

export const selectLinkedChartsConfig = (state) => {
  if (
    state.dataConfigCharts.configLinkedChartsData &&
    state.dataConfigCharts.configLinkedChartsData.linkedCharts
  ) {
    return state.dataConfigCharts.configLinkedChartsData.linkedCharts;
  }
  return null;
};

export default dataConfigChartReducer;
