import { NavigateFunction } from 'react-router-dom';
import type { SettingsTypes } from '../containers/Settings/types';
import type {
  AllTimeSelected,
  FeedPropertyTypes,
  ObjectToClone,
} from '../reducers/types/appliedPropertiesTypes';
import type { SettingsWithMembers } from '../components/Vector/types/vectorDataChartTypes';
import {
  FGG,
  DispenseWeight,
  type LocalStorageAcceptableKeys,
  type IGenericTable,
  type Nullable,
} from './types';
import type { FarmOptionsTypes } from '../components/TableFilter/types';
import {
  DATA_MIDDLE_CHART_ID,
  DATA_TOP_CHART_ID,
  expandedSectors,
  filtersByTable,
} from './constants';
import { cloneDeep } from '../components/Vector/graphUtils/commonUtils';

export const uppercaseFirstLetter = (string: string) => {
  const uppercaseFirstLetterString =
    string.charAt(0).toUpperCase() + string.slice(1);
  return uppercaseFirstLetterString;
};

const execRegexPattern = (pattern: RegExp, string: string) => {
  const result = pattern.exec(string);
  pattern.lastIndex = 0;

  return result;
};

const astronautNRegexMatch = /^\w*-(?<astronautNumber>\d+)$/g;
export const astronautsSortedByNumber = (
  prevAstr: SettingsTypes.FarmAstronaut,
  nextAstr: SettingsTypes.FarmAstronaut,
) => {
  const prevAstrNumber = execRegexPattern(astronautNRegexMatch, prevAstr.name);
  const nextAstrNumber = execRegexPattern(astronautNRegexMatch, nextAstr.name);

  if (
    prevAstrNumber &&
    prevAstrNumber.groups &&
    nextAstrNumber &&
    nextAstrNumber.groups
  ) {
    return (
      Number(prevAstrNumber.groups.astronautNumber) -
      Number(nextAstrNumber.groups.astronautNumber)
    );
  }

  return -1;
};

export const senseCustomSorting = (
  val1: string | number | boolean,
  val2: string | number | boolean,
  direction: 'asc' | 'desc',
): number => {
  // if values are equal
  if (val1 === val2) {
    return 0;
  }

  // both values null
  if (isNullOrEmptyValue(val1) && isNullOrEmptyValue(val2)) {
    return 0;
  }

  // ASC
  if (direction === 'asc') {
    // explicitly check for number because BE return numbers as strings
    if (isNullOrEmptyValue(val1) && !isNullOrEmptyValue(val2)) {
      return 1;
    }
    if (isNullOrEmptyValue(val2) && !isNullOrEmptyValue(val1)) {
      return -1;
    }

    // both numbers
    if (isNumberValue(val1) && isNumberValue(val2)) {
      if (Number(val1) === Number(val2)) {
        return val1.toString().length > val2.toString().length ? 1 : -1;
      }
      return Number(val1) > Number(val2) ? 1 : -1;
    }

    // if only one of the values is number sort it above the other
    if (isNumberValue(val1)) {
      return -1;
    }
    if (isNumberValue(val2)) {
      return 1;
    }

    //  sort strings and booleans
    const firstArg = val1?.toString().toLowerCase();
    const secondArg = val2?.toString().toLowerCase();

    return Intl.Collator('nl').compare(firstArg, secondArg);
  }
  if (isNullOrEmptyValue(val1) && !isNullOrEmptyValue(val2)) {
    return -1;
  }
  if (isNullOrEmptyValue(val2) && !isNullOrEmptyValue(val1)) {
    return 1;
  }

  // both numbers
  if (isNumberValue(val1) && isNumberValue(val2)) {
    return Number(val1) > Number(val2) ? -1 : 1;
  }

  // if only one of the values is number sort it above the other
  if (isNumberValue(val1)) {
    return 1;
  }
  if (isNumberValue(val2)) {
    return -1;
  }

  const firstArg = val1?.toString().toLowerCase();
  const secondArg = val2?.toString().toLowerCase();
  return Intl.Collator('nl').compare(secondArg, firstArg);
};

export const isNumberValue = (value: string | number | boolean): boolean => {
  return (
    value !== null &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(value as any) &&
    typeof Number(value) === 'number' &&
    !['false', 'true'].includes(value.toString().toLowerCase())
  );
};

export const isNullOrEmptyValue = (
  value: string | number | boolean | null,
): boolean => {
  return value === '' || value === null;
};

export const mergeEachMemberWithObjectClone = (
  configuration: FeedPropertyTypes | SettingsWithMembers<{ id: string }>,
  objectToClone: ObjectToClone,
  prevConfiguration: AllTimeSelected | null = null,
  urlKey: string = '',
  navigate?: NavigateFunction,
) => {
  const urlOffEvents = getUrlOrStorageFilters('vectorConfig', urlKey, navigate);

  const clonedObject = cloneDeep(objectToClone);
  if (configuration) {
    Object.keys(configuration).map((categoryKey) => {
      return Object.keys(configuration[categoryKey].members).map(
        (memberKey, idx) => {
          return Object.keys(clonedObject).forEach((prop) => {
            const containedInAllTimeSelected =
              prevConfiguration &&
              (prevConfiguration[categoryKey].includes(
                configuration[categoryKey].members[memberKey]?.id,
              ) ||
                prevConfiguration[categoryKey].includes(memberKey));

            // for Feed grabber grabs - check if the selected feed is present when you change range
            if (prop === 'selected') {
              if (categoryKey === FGG && idx === 0) {
                configuration[categoryKey].members[memberKey][prop] = true;
                return;
              }

              const isOffInUrl =
                urlOffEvents?.split(',').includes(memberKey) ?? false;

              configuration[categoryKey].members[memberKey][prop] =
                containedInAllTimeSelected || urlKey
                  ? !isOffInUrl
                  : clonedObject.selected;
            } else {
              configuration[categoryKey].members[memberKey][prop] =
                clonedObject[prop];
            }
          });
        },
      );
    });
  }
};

export const getCurrentActiveLoadingPerformanceGraph = (
  obj,
  activeSelectedFence,
) => {
  const areAllSubMenusOpen = Object.values(obj).every((x) => x === true);
  if (activeSelectedFence) {
    return activeSelectedFence;
  }
  if (areAllSubMenusOpen) {
    return FGG;
  }
  if (obj[FGG]) {
    return FGG;
  }
  if (obj[DispenseWeight]) {
    return DispenseWeight;
  }
  return '';
};

const getUrlOrStorageFilters = (
  storageKey: LocalStorageAcceptableKeys,
  urlKey: string,
  navigate?: NavigateFunction,
): Nullable<string> => {
  const urlParams = getSearchParams(urlKey);
  const localStorageItem = localStorage.getObject(storageKey);

  if (urlParams) {
    localStorage.updateObjectProperty(storageKey, urlKey, urlParams);
    return urlParams;
  }

  if (localStorageItem) {
    const value = localStorageItem[urlKey];

    if (value) {
      updateSearchParams(urlKey, value, true, navigate);
      return value;
    }
  }

  return null;
};

export const updateSearchParams = (
  key: string,
  value: string = '',
  replace?: boolean,
  navigate?: NavigateFunction,
) => {
  if (window.location && window.history) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(key, value);

    if (!value) {
      urlParams.delete(key);
    }

    if (navigate) {
      navigate(
        {
          search: urlParams.toString(),
        },
        { replace: true },
      );
      return;
    }

    if (replace) {
      window.history.replaceState({}, '', `?${urlParams}`);
    } else {
      window.history.pushState({}, '', `?${urlParams}`);
    }
  }
};

export const updateMultipleSearchParams = (
  params: Array<{
    key: string;
    value?: string;
  }>,
  replace?: boolean,
  navigate?: NavigateFunction,
) => {
  if (window.location && window.history) {
    const urlParams = new URLSearchParams(window.location.search);
    params.forEach((item) => {
      if (item.value) {
        urlParams.set(item.key, item.value);
      } else {
        urlParams.delete(item.key);
      }
    });

    if (navigate) {
      navigate(
        {
          search: urlParams.toString(),
        },
        { replace: true },
      );

      return;
    }

    if (replace) {
      window.history.replaceState({}, '', `?${urlParams}`);
    } else {
      window.history.pushState({}, '', `?${urlParams}`);
    }
  }
};

export const getSearchParams = (key: string) => {
  if (window.location) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
  }
  return null;
};

export const restoreFilterColumnsFromLocalStorage = (
  localStorageColumns: IGenericTable['filters'],
  tableName: string,
  optionalFieldsConfig: FarmOptionsTypes.Selection[],
  filterName: string,
) => {
  const possibleFilterTypes = filtersByTable[tableName].map((item) =>
    item.toLowerCase(),
  );

  return Object.keys(localStorageColumns)
    .map((key) => {
      if (
        possibleFilterTypes.includes(key.toLowerCase()) &&
        filterName === key
      ) {
        const localStorageFilters = localStorageColumns[key];

        const newColumns = optionalFieldsConfig.filter((item) =>
          localStorageFilters.includes(item.key),
        );
        updateSearchParams(filterName, localStorageFilters.join(','), true);

        return {
          key,
          columns: newColumns,
        };
      }

      return null;
    })
    .filter((item) => item !== null)?.[0];
};

export const compareArraysValues = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((element) => arr2.includes(element));
};

export const combineSignalsFromUrlToKeys = (
  signals1: Nullable<string>,
  signals2: Nullable<string>,
): Record<string, typeof DATA_TOP_CHART_ID | typeof DATA_MIDDLE_CHART_ID> => {
  const newKeys: Record<
    string,
    typeof DATA_TOP_CHART_ID | typeof DATA_MIDDLE_CHART_ID
  > = {};

  if (signals1) {
    signals1.split(',').forEach((signal) => {
      newKeys[signal] = DATA_TOP_CHART_ID;
    });
  }

  if (signals2) {
    signals2.split(',').forEach((signal) => {
      newKeys[signal] = DATA_MIDDLE_CHART_ID;
    });
  }

  return newKeys;
};

export const preserveExpandedSelectorsValues = (element, key) => {
  const urlExpandedSectors = localStorage.getObject(key)?.[expandedSectors];

  if (urlExpandedSectors) {
    if (urlExpandedSectors?.split(',').includes(element)) {
      const sectorsArray = urlExpandedSectors?.split(',');
      const indexElement = sectorsArray.indexOf(element);
      sectorsArray.splice(indexElement, 1);
      // setExpanded(false);
      updateSearchParams(expandedSectors, sectorsArray.join(','), false);

      localStorage.updateObjectProperty(
        key,
        expandedSectors,
        sectorsArray.join(','),
      );
    } else {
      updateSearchParams(
        expandedSectors,
        `${urlExpandedSectors},${element}`,
        false,
      );

      localStorage.updateObjectProperty(
        key,
        expandedSectors,
        `${urlExpandedSectors},${element}`,
      );
    }
  } else {
    updateSearchParams(expandedSectors, element, false);

    localStorage.updateObjectProperty(key, expandedSectors, element);
  }
};
