import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { MOPINION_FORM_ID } from '../../customHooks/useMopinion';
import SideBarButton from './SideBarButton';
import { useAppSelector } from '../../../reducers/rootReducer';
import { translationSelectors } from '../../../reducers/translationSlice';
import { farmsSelectors } from '../../../reducers/farmsSlice';

interface CustomWindow extends Window {
  srv?: any; // Define the srv property as optional
}

declare let window: CustomWindow;

function DownButtons() {
  const preferredLanguage = useAppSelector(
    translationSelectors.selectPreferredLanguages,
  );
  const [wasFeedbackOpenedViaUrl, setWasFeedbackOpenedViaUrl] =
    useState<boolean>(false);

  const helpButtonText = preferredLanguage === 'en' ? 'Help' : 'Hulp';
  const isUserCurrentlyAtFeedbackPage = useMatch('/feedback');
  const farms = useAppSelector(farmsSelectors.selectFarms);

  useEffect(() => {
    if (isUserCurrentlyAtFeedbackPage) {
      if (window.srv && !wasFeedbackOpenedViaUrl) {
        setTimeout(() => {
          setWasFeedbackOpenedViaUrl(true);
          onClickHandler();
        }, 1000);
      }
    }
  }, [farms]);

  const onClickHandler = () => {
    if (window.srv) {
      window.srv.openModal(true, MOPINION_FORM_ID);
    }
  };

  return (
    <div className="down bordertop">
      <SideBarButton
        link="https://lely.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=231016085348207"
        iconClassName="lely-icon-help"
        menuOptionTitle={helpButtonText}
        navigateTo={false}
        translation={false}
      />
      <SideBarButton
        iconClassName="lely-icon-chat-bubble"
        onClickFunction={onClickHandler}
        menuOptionTitle="Feedback"
        translation={false}
        navigateTo={false}
      />
    </div>
  );
}

export default DownButtons;
