import { select as d3Select } from 'd3-selection';
import { range as d3Range } from 'd3-array';

export const NUMBER_OF_CANVASES_TO_CREATE_IN_INNERMOST_ZOOM_LEVEL = 2;
export const MAX_CANVAS_WIDTH_IN_PIXELS = 16_000;

// #region multiple canvases logic
export const getCanvasWidthInPixelsByCanvasIndex = (
  canvasIndex,
  zoomLevelWidthInPixels,
  maxCanvasWidthInPixels,
) => {
  const potentialWidthOfCanvas =
    zoomLevelWidthInPixels - canvasIndex * maxCanvasWidthInPixels;
  return Math.min(potentialWidthOfCanvas, maxCanvasWidthInPixels);
};

export const getInnermostZoomLevelBasedOnNumberOfCanvasesGenerated = (
  widthInPixels,
) => {
  const calculatedInnermostZoomLevel =
    (NUMBER_OF_CANVASES_TO_CREATE_IN_INNERMOST_ZOOM_LEVEL *
      MAX_CANVAS_WIDTH_IN_PIXELS) /
    widthInPixels;
  return calculatedInnermostZoomLevel;
};

export const getCanvasXCoordinateByCanvasIndex = (
  canvasIndex,
  maxCanvasWidthInPixels,
) => {
  return canvasIndex * maxCanvasWidthInPixels;
};

export const getZoomLevelWidthForInnermostZoomLevel = () => {
  /** The width of the chart should be equal to the width of the composite state graph
   *  since the billboard js chart width corresponds to the whole component width, this means the width of the
   * Y-axis labels, paddings and the chart. Therefore additional 46 pixels are added to compensate y axis labels and paddings
   * so we can end up with a actual chart width equal to the composite state graph and avoid misalignments.
   */
  return (
    NUMBER_OF_CANVASES_TO_CREATE_IN_INNERMOST_ZOOM_LEVEL *
      MAX_CANVAS_WIDTH_IN_PIXELS +
    46
  );
};

export const createCanvasWithIndex = (
  canvasIndex,
  zoomLevelWidthInPixels,
  visibleAreaHeightInPixels,
  maxCanvasWidthInPixels,
  referenceElement,
  canvasCssClassname,
  topValue,
) => {
  const canvasWidth = getCanvasWidthInPixelsByCanvasIndex(
    canvasIndex,
    zoomLevelWidthInPixels,
    maxCanvasWidthInPixels,
  );
  const canvasHeight = visibleAreaHeightInPixels;
  const canvasXCoordinate = getCanvasXCoordinateByCanvasIndex(
    canvasIndex,
    maxCanvasWidthInPixels,
  );
  const expectedXCordinate =
    canvasXCoordinate === 0 ? 0 : canvasXCoordinate - 1;
  const canvas = d3Select(referenceElement) // layerContainerRef.current
    .append('canvas')
    .attr('class', canvasCssClassname)
    .attr('width', canvasWidth)
    .attr('height', canvasHeight)
    .style('position', 'absolute')
    .style('left', `${expectedXCordinate}px`)
    .style('top', `${topValue}px`);

  return canvas;
};

export const getCanvasContextIndicesByElementXAndElementWidth = (
  elementX,
  elementWidthInPx,
  maxCanvasWidthInPixels,
) => {
  const startXCanvasIndex = getCanvasContextIndexByElementX(
    elementX,
    maxCanvasWidthInPixels,
  );
  const endXCanvasIndex = getCanvasContextIndexByElementX(
    elementX + elementWidthInPx,
    maxCanvasWidthInPixels,
  );
  let canvasContextIndices = [];
  if (startXCanvasIndex !== endXCanvasIndex) {
    canvasContextIndices = d3Range(startXCanvasIndex, endXCanvasIndex + 1);
  } else {
    canvasContextIndices.push(startXCanvasIndex);
  }
  return canvasContextIndices;
};

export const getCanvasContextsByElementX = (
  elementX,
  elementWidthInPx,
  maxCanvasWidthInPixels,
  canvasContexts,
) => {
  const canvasContextIndices = getCanvasContextIndicesByElementXAndElementWidth(
    elementX,
    elementWidthInPx,
    maxCanvasWidthInPixels,
  );
  return canvasContextIndices.map((currentCanvasContextIndex) => {
    return canvasContexts[currentCanvasContextIndex];
  });
};

export const getCanvasContextIndexByElementX = (
  elementX,
  maxCanvasWidthInPixels,
) => {
  const canvasIndex = Math.floor(elementX / maxCanvasWidthInPixels);
  return canvasIndex;
};

export const getLocalCanvasXByElementX = (elementX, maxCanvasWidthInPixels) => {
  // elementX is returned by the scaleFunction which was initialized/bound to the entire zoom level width range
  return elementX % maxCanvasWidthInPixels;
};

export const drawElementOnCanvas = (
  currentObjectToBeRendered,
  xPositionInPx,
  yPositionInPx,
  widthInPx,
  heightInPx,
  fillStyle,
  strokeStyle,
  canvasContexts,
  maxCanvasWidthInPixels,
  scaleFunction,
  isDynamic,
  stateArrow,
) => {
  // eslint-disable-next-line no-param-reassign
  canvasContexts = getCanvasContextsByElementX(
    xPositionInPx,
    widthInPx,
    maxCanvasWidthInPixels,
    canvasContexts,
  );
  drawElementOnCanvasContexts(
    currentObjectToBeRendered,
    xPositionInPx,
    yPositionInPx,
    widthInPx,
    heightInPx,
    fillStyle,
    strokeStyle,
    maxCanvasWidthInPixels,
    scaleFunction,
    canvasContexts,
    isDynamic,
    stateArrow,
  );
};

export const drawElementOnCanvasContexts = (
  currentObjectToBeRendered,
  xPositionInPx,
  yPositionInPx,
  widthInPx,
  heightInPx,
  fillStyle,
  strokeStyle,
  maxCanvasWidthInPixels,
  scaleFunction,
  canvasContexts,
  isDynamic,
  stateArrow,
) => {
  if (canvasContexts.length === 1) {
    // eslint-disable-next-line no-param-reassign
    xPositionInPx = getLocalCanvasXByElementX(
      xPositionInPx,
      maxCanvasWidthInPixels,
    );
    drawOnSpecificCanvas(
      canvasContexts[0],
      xPositionInPx,
      yPositionInPx,
      widthInPx,
      heightInPx,
      fillStyle,
      stateArrow,
      strokeStyle,
      undefined,
    );
  } else if (canvasContexts.length > 1) {
    canvasContexts.forEach(
      (currentCanvasContext, indexInCanvasContextsArray) => {
        let elementLocalCanvasX;
        let elementWidthInPxForCurrentCanvas;
        if (indexInCanvasContextsArray === 0) {
          elementLocalCanvasX = getLocalCanvasXByElementX(
            xPositionInPx,
            maxCanvasWidthInPixels,
          );
          elementWidthInPxForCurrentCanvas =
            maxCanvasWidthInPixels - elementLocalCanvasX;
        } else if (indexInCanvasContextsArray === canvasContexts.length - 1) {
          elementLocalCanvasX = 0;
          elementWidthInPxForCurrentCanvas = getLocalCanvasXByElementX(
            xPositionInPx + widthInPx,
            maxCanvasWidthInPixels,
          );
        } else {
          elementLocalCanvasX = 0;
          elementWidthInPxForCurrentCanvas = maxCanvasWidthInPixels;
        }
        drawOnSpecificCanvas(
          currentCanvasContext,
          elementLocalCanvasX,
          yPositionInPx,
          elementWidthInPxForCurrentCanvas,
          heightInPx,
          fillStyle,
          stateArrow,
          strokeStyle,
          indexInCanvasContextsArray,
        );
      },
    );
  }
};

export const drawOnSpecificCanvas = (
  canvasContext,
  xPositionInPx,
  yPositionInPx,
  widthInPx,
  heightInPx,
  fillStyle,
  stateArrow,
  strokeStyle,
  splitedActivityIndex,
) => {
  initializeCanvasContextWithFillAndStroke(
    canvasContext,
    fillStyle,
    strokeStyle,
  );
  if (stateArrow?.showArrow && canvasContext) {
    canvasContext.beginPath();
    canvasContext.moveTo(xPositionInPx, yPositionInPx);
    canvasContext.lineTo(xPositionInPx, yPositionInPx + heightInPx);
    canvasContext.lineTo(
      xPositionInPx + widthInPx,
      yPositionInPx + heightInPx / 2,
    );
    canvasContext.closePath();
    canvasContext.fill();
    if (stateArrow.hasStroke) {
      canvasContext.strokeStyle = 'rgba(0, 0, 0, 0.5)';
      canvasContext.lineWidth = 2;
      canvasContext.stroke();
    }
  } else if (canvasContext && canvasContext.fillRect) {
    if (fillStyle) {
      canvasContext.fillRect(
        xPositionInPx,
        yPositionInPx,
        widthInPx,
        heightInPx,
      );
    }
    if (strokeStyle && strokeStyle !== 'white') {
      if (splitedActivityIndex === undefined) {
        canvasContext.strokeRect(
          xPositionInPx,
          yPositionInPx,
          widthInPx,
          heightInPx,
        );
      } else if (splitedActivityIndex === 0) {
        // left part
        canvasContext.beginPath();
        canvasContext.moveTo(
          xPositionInPx + widthInPx,
          yPositionInPx + heightInPx,
        );
        canvasContext.lineTo(xPositionInPx, yPositionInPx + heightInPx);
        canvasContext.lineTo(xPositionInPx, yPositionInPx);
        canvasContext.lineTo(xPositionInPx + widthInPx, yPositionInPx);
        canvasContext.stroke();
      } else if (splitedActivityIndex === 1) {
        // right part
        canvasContext.beginPath();
        canvasContext.moveTo(xPositionInPx, yPositionInPx);
        canvasContext.lineTo(xPositionInPx + widthInPx, yPositionInPx);
        canvasContext.lineTo(
          xPositionInPx + widthInPx,
          yPositionInPx + heightInPx,
        );
        canvasContext.lineTo(xPositionInPx, yPositionInPx + heightInPx);
        canvasContext.stroke();
      }
    }
    if (
      strokeStyle === 'white' &&
      canvasContext &&
      splitedActivityIndex === undefined
    ) {
      canvasContext.lineWidth = 1.5;
      canvasContext.strokeStyle = 'rgba(255, 255, 255, 1)';
      canvasContext.beginPath();
      canvasContext.moveTo(xPositionInPx, yPositionInPx);
      canvasContext.lineTo(xPositionInPx, yPositionInPx + heightInPx);
      canvasContext.stroke();

      canvasContext.beginPath();
      canvasContext.moveTo(xPositionInPx + widthInPx, yPositionInPx);
      canvasContext.lineTo(
        xPositionInPx + widthInPx,
        yPositionInPx + heightInPx,
      );
      canvasContext.stroke();
    }
  }
};

export const initializeCanvasContextWithFillAndStroke = (
  canvasContext,
  fillStyle,
  strokeStyle,
) => {
  if (canvasContext && canvasContext.fillRect) {
    if (fillStyle) {
      canvasContext.fillStyle = fillStyle;
    }
    if (strokeStyle && strokeStyle !== 'white') {
      canvasContext.strokeStyle = strokeStyle;
      canvasContext.lineWidth = 2;
    }
  }
};

export const initializeCanvasesForInnermostZoomLevel = (
  zoomLevelWidthInPixels,
  visibleAreaHeightInPixels,
  maxCanvasWidthInPixels,
  referenceElement,
  canvasCssClassname,
  numberOfCanvasesThatNeedToBeCreated,
  topValue,
) => {
  const canvasContexts = [];
  for (
    let canvasIndex = 0;
    canvasIndex < numberOfCanvasesThatNeedToBeCreated;
    canvasIndex++
  ) {
    canvasContexts.push(
      createCanvasWithIndex(
        canvasIndex,
        zoomLevelWidthInPixels,
        visibleAreaHeightInPixels,
        maxCanvasWidthInPixels,
        referenceElement,
        canvasCssClassname,
        topValue,
      ),
    );
  }
  return canvasContexts;
};

export const initializeCanvases = (
  zoomLevelWidthInPixels,
  visibleAreaHeightInPixels,
  maxCanvasWidthInPixels,
  referenceElement,
  canvasCssClassname,
  isDynamic,
  numberOfCanvasesThatNeedToBeCreated,
  topValue,
) => {
  // eslint-disable-next-line no-param-reassign
  numberOfCanvasesThatNeedToBeCreated = isDynamic
    ? numberOfCanvasesThatNeedToBeCreated
    : Math.ceil(zoomLevelWidthInPixels / maxCanvasWidthInPixels);
  const canvasContexts = [];
  if (isDynamic) {
    return initializeCanvasesForInnermostZoomLevel(
      zoomLevelWidthInPixels,
      visibleAreaHeightInPixels,
      maxCanvasWidthInPixels,
      referenceElement,
      canvasCssClassname,
      numberOfCanvasesThatNeedToBeCreated,
      topValue,
    );
  }
  for (
    let canvasIndex = 0;
    canvasIndex < numberOfCanvasesThatNeedToBeCreated;
    canvasIndex++
  ) {
    canvasContexts.push(
      createCanvasWithIndex(
        canvasIndex,
        zoomLevelWidthInPixels,
        visibleAreaHeightInPixels,
        maxCanvasWidthInPixels,
        referenceElement,
        canvasCssClassname,
        topValue,
      ),
    );
  }
  return canvasContexts;
};
