/* eslint-disable no-console */
import { call, put, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  downloadLogFiles,
  setDownloadError,
  setLogsDownloadURL,
} from '../../reducers/logsSlice';
import { LogsApiType } from '../../api/lelyBackend/logsApi';
import { AwaitedApiResultType } from '../../common/types';

export default function* logsFilterMenuSaga(api: LogsApiType) {
  while (true) {
    const logNodesAction: PayloadAction<{
      entityId: string;
      startDate: string;
      endDate: string;
      fileNames: string[];
      instanceId: string;
    }> = yield take(downloadLogFiles.type);

    if (logNodesAction.payload) {
      const { entityId, startDate, endDate, fileNames, instanceId } =
        logNodesAction.payload;
      yield call(
        getLogsMenuOptions,
        api,
        entityId,
        startDate,
        endDate,
        fileNames,
        instanceId,
      );
    }
  }
}

function* getLogsMenuOptions(
  api: LogsApiType,
  entityId: string,
  startTime: string,
  endTime: string,
  fileNames: string[],
  instanceId: string,
) {
  try {
    const downloadURL: AwaitedApiResultType<typeof api.downloadLogNodes> =
      yield call(api.downloadLogNodes, entityId, startTime, endTime, fileNames);

    yield put(
      setLogsDownloadURL({ logsDownloadURL: downloadURL.data, instanceId }),
    );
  } catch (error: any) {
    if (error?.response?.data?.message)
      yield put(
        setDownloadError({
          downloadingBlocked: false,
          message: error,
        }),
      );
  }
}
