let lastLineChartMouseX = 0;
export const setLastLineChartMouseX = (newLastLineChartMouseX) => {
  lastLineChartMouseX = newLastLineChartMouseX;
};

export const getLastLineChartMouseX = () => {
  return lastLineChartMouseX;
};

let lastLineChartMouseY = {};
export const setLastLineChartMouseY = (newLastLineChartMouseY) => {
  lastLineChartMouseY = newLastLineChartMouseY;
};

export const getLastLineChartMouseY = () => {
  return lastLineChartMouseY;
};

let lastZoomStartDate = {};
export const setLastZoomStartDate = (newLastZoomStartDate) => {
  lastZoomStartDate = newLastZoomStartDate;
};

export const getLastZoomStartDate = () => {
  return lastZoomStartDate;
};

let lastZoomEndDate = {};
export const setLastZoomEndDate = (newLastZoomEndDate) => {
  lastZoomEndDate = newLastZoomEndDate;
};

export const getLastZoomEndDate = () => {
  return lastZoomEndDate;
};

let latestVectorChartPan = 0;
export const setLatestVectorChartPan = (newVectorChartPan) => {
  latestVectorChartPan = newVectorChartPan;
};

export const getLatestVectorChartPan = () => {
  return latestVectorChartPan;
};

let latestDragEventDate = new Date();
export const setLatestDragEventDate = (newDragEventDate) => {
  latestDragEventDate = newDragEventDate;
};

export const getLatestDragEventDate = () => {
  return latestDragEventDate;
};

let initialPanValue = new Date();
export const setInitialPanValue = (newInitialPanValue) => {
  initialPanValue = newInitialPanValue;
};

export const getInitialPanValue = () => {
  return initialPanValue;
};

let latestDragXPosition = 0;
export const setLatestDragXPosition = (newDragXPosition) => {
  latestDragXPosition = newDragXPosition;
};

export const getLatestDragXPosition = () => {
  return latestDragXPosition;
};

let dragStartXPosition = 0;
export const setDragStartXPosition = (newDragStartXPosition) => {
  dragStartXPosition = newDragStartXPosition;
};

export const getDragStartXPosition = () => {
  return dragStartXPosition;
};
