// stores the latest date/time of requests per type
// for instance:
// GET_VECTOR_DATA_CHART_SERVICE_URL: <date_time> of latest request of that type
// We need this in order to ignore certain responses from time to time.
// Works similarly to LoadManager, but not quite the same.
//
// There was a category of bugs which happened on really slow speeds where
// clicking through the farm VDEs, etc. caused showing the data for the old VDE for a second there in certain cases
// either in the Breadcrumb component or even the chart themselves.
// This happened because requests that were made prior received their responses
// slightly before the new requests for that same data received their responses/
// or failed to receive those responses with error 404 or 500.
// eslint-disable-next-line import/prefer-default-export
export const requestDictionary = {};
