import {
  GET_FARM_DETAILS_REQUEST,
  GET_FARM_DETAILS_REQUEST_SUCCESS,
  GET_FARM_DETAILS_REQUEST_ERROR,
  SET_CURRENT_SELECTED_ENTITY,
  RESET_FARM_DETAILS,
} from './types';

/** Create action which redirect to given page.
 * @param {Object} selectedEntity - Path to page example: '/home'
 * @return {Object} Return type and selectedEntity
 */
export function setCurrentSelectedEntity(selectedEntity) {
  return {
    type: SET_CURRENT_SELECTED_ENTITY,
    payload: { ...selectedEntity },
  };
}

/** Create action which get token and farmId.
 * @param {string} accessToken - User access token
 * @param {string} farmId - Farm id
 * @param {string} farmName - Farm name
 * @return {Object} Return type, accessToken and farmId
 */
export function getFarmDetails(accessToken, farmId, farmName) {
  return {
    type: GET_FARM_DETAILS_REQUEST,
    payload: {
      accessToken,
      farmId,
      farmName,
    },
  };
}

/** Create action which get provided farmDetails
 * {id,farmName,farmEntities} and set them to redux store.
 * @param {Object} farmDetails - Path to page example: '/home'
 * @param {Number} requestUuid - Second parameter that allows us to avoid showing data for previous farm that was clicked
 * but then you went back from it quickly into the FarmsTable component (related to bug SLDAC-10593)
 * @return {Object} Return type and farmDetails
 */
export function getFarmDetailsSuccess(farmDetails, requestUuid) {
  return {
    type: GET_FARM_DETAILS_REQUEST_SUCCESS,
    payload: { ...farmDetails, requestUuid },
  };
}

/** Create action that resets Farm Details data (in order not to show Farm Details data for previous farm)
 * @return {Object} Action object
 */
export function resetFarmDetails() {
  return {
    type: RESET_FARM_DETAILS,
    payload: {},
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getFarmDetailsError(errorMessage) {
  return {
    type: GET_FARM_DETAILS_REQUEST_ERROR,
    payload: errorMessage,
  };
}
