import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import LogoutButton from '../../../components/LogoutButton/LogoutButton';
import { useAppSelector } from '../../../reducers/rootReducer';
import { PREFERRED_UNIT, UNIT_IMPERIAL, UNIT_METRIC } from '../../constants';
import DropDown from '../Dropdown/Dropdown';
import RadioGroupSelection from '../RadioGroupSelection/RadioGroupSelection';
import './UserDialog.scss';
import { setUserPreference } from '../../../reducers/userSessionSlice';
import { selectInterceptorsStatus } from '../../../reducers/interceptorsSlice';
import {
  getLanguages,
  translationSelectors,
} from '../../../reducers/translationSlice';

function UserDialogContent({ language, setLanguage, unit, setUnit }) {
  const dispatch = useDispatch();
  const interceptorsStatus = useAppSelector(selectInterceptorsStatus);

  const { accessToken } = useAppSelector((state) => state.userSession);
  const { currentUser: username } = useAppSelector(
    (state) => state.userSession,
  );
  const availableLanguages = useAppSelector(
    translationSelectors.selectAvailableLanguages,
  );

  useEffect(() => {
    if (accessToken && interceptorsStatus === 'initialized') {
      dispatch(getLanguages());
      const metricPreference: string | null =
        localStorage.getObject(PREFERRED_UNIT);
      if (metricPreference) {
        dispatch(setUserPreference(metricPreference));
        setUnit(metricPreference);
      } else {
        setUnit('Metric');
        localStorage.setObject(PREFERRED_UNIT, 'Metric');
        dispatch(setUserPreference('Metric'));
      }
    }
  }, [accessToken, interceptorsStatus]);

  const updateUnit = (newSelectedRadioButton) => {
    setUnit(newSelectedRadioButton);
  };

  return (
    <div className="user-dialog-sub-wrapper">
      <div className="user-dialog-container-wrapper">
        <DialogContent className="user-dialog-container">
          <DialogContentText className="user-dialog-title-text fs--h7">
            User
          </DialogContentText>
          <DialogActions className="user-dialog-logout-action-wrapper">
            <DialogContentText className="user-dialog-user-name">
              {username}
            </DialogContentText>
            <LogoutButton />
          </DialogActions>
        </DialogContent>
        <DialogContent className="user-dialog-container">
          <DialogContentText className="user-dialog-title-text fs--h7">
            <LanguageIcon />
            Language
          </DialogContentText>
          <DialogActions className="user-dialog-dropdown">
            <DropDown
              defaultValue={language}
              onUpdate={(lang) => setLanguage(lang)}
              dropdownData={availableLanguages}
            />
          </DialogActions>
          {language !== 'nl' && language !== 'en' && (
            <DialogContentText className="user-dialog-title-text">
              Current language is only applicable for Vector alarm texts.
              English will be used for the rest of the application
            </DialogContentText>
          )}
        </DialogContent>
        <DialogContent className="user-dialog-container">
          <DialogContentText className="user-dialog-title-text fs--h7">
            Units
          </DialogContentText>
          <p className="fs--d-header under-title-msg">
            Only applicable for Vector
          </p>
          <DialogActions className="user-dialog-unit">
            <RadioGroupSelection
              labelsArray={[UNIT_METRIC, UNIT_IMPERIAL]}
              onSelectionChange={updateUnit}
              defaultValue={unit}
            />
          </DialogActions>
        </DialogContent>
      </div>
    </div>
  );
}

export default UserDialogContent;
