import { FiltersByTableType, Robots } from './types';

// React Pinch Zoom Pan library
export const VECTOR_LINE_CHART_HEIGHT_IN_PERCENT_BASED_ON_VIEWPORT_HEIGHT = 20;
export const VECTOR_MULTILINE_GRAPH_LEFT_RIGHT_OFFSET = 60;
export const IS_DATE_VALID = true;
export const IS_DATE_INVALID = false;
export const MAX_UNITS_SELECTED_LIMIT = 2;

export const UPDATE_HISTORY_LAST_MONTH = 'lm';
export const UPDATE_HISTORY_LAST_3_MONTHS = 'l3m';
export const UPDATE_HISTORY_LAST_YEAR = 'ly';
export const ZOOM_WIDTH_CONSTRAINTS = {
  lm: 3000,
  l3m: 40000,
  ly: 36000,
};
export const UI_DATE_FORMAT_WEEK_DAY = 'EEE dd/MM';
export const UI_DATE_FORMAT_FOR_CALC = 'MMM dd, yyyy HH:mm:ss'; // used with toFarmLocalTime function to get date in string format that can be used for date object initialization, month should be in first position because if the date string is "25/08/2024 10:32:21" it will lead to Invalid Date error when passed to new Date()
export const UI_DATE_FORMAT = 'dd/MM/yyyy HH:mm:ss';
export const UI_DATE_FORMAT_WITH_OFFSET = 'MMM dd, yyyy HH:mm:ssXXX';
export const UI_DATE_WITHOUT_HOUR_FORMAT = 'dd/MM/yyyy';
export const UI_DATE_MONTH_FORMAT = 'dd/MM';
export const UI_MONTH_YEAR_FORMAT = 'MM/yyyy';
export const UI_DATE_FORMAT_MILLISECONDS = 'dd/MM/yyyy HH:mm:ss.SSS';
export const HOURS_DATE_PICKER_FORMAT = 'HH:mm:ss';
export const DATE_T_FORMAT_BE = "yyyy-MM-dd'T'HH:mm:ss";
export const MOMENTJS_DATE_T_FORMAT_URL = "yyyy-MM-DD'T'HH:mm:ss";
export const DATE_T_FORMAT_BE_MILLISECONDS = "yyyy-MM-dd'T'HH:mm:ss.SSS";
export const MOMENTJS_UI_DATE_FORMAT = 'DD/MM/yyyy HH:mm:ss';
export const VECTOR_KPI_POST_DATE_FORMAT = 'yyyy-MM-dd';
export const LOGS_TIME_COMPARISON_FORMAT = 'yyyy/MM/dd HH:mm';

export const CONTAINER_RATION = 100;
export const MAX_SCALE = 3;
export const MIN_SCALE = 1;
export const DATA_NO_CHART_ID = '0';
export const DATA_TOP_CHART_ID = '1';
export const DATA_MIDDLE_CHART_ID = '2';
export const DATA_BOTTOM_CHART_ID = '3';
export const CHART_TYPE_LINE = 'line';
export const CHART_TYPE_STATUS = 'status';
export const CHART_TYPE_SPLINE = 'spline';
export const STATUS_ID_APPENDIX = 'status';
export const CHART_MULTI_TOGGLE_BUTTON_OPTIONS = [
  DATA_NO_CHART_ID,
  DATA_TOP_CHART_ID,
  DATA_MIDDLE_CHART_ID,
];
export const START_DATE_NEEDS_TO_BE_BEFORE_END_DATE_ERROR_TEXT =
  'Start date needs to be before end date';
export const START_DATE_NEEDS_TO_BE_DIFFERENT_FROM_END_DATE_ERROR_TEXT =
  'Start date need to be at least 1 second before the end date';
export const RANGE_OVER_1_MONTH_ERROR_TEXT =
  'Maximum allowed range of one month';
export const NO_DATE_SELECTED_ERROR_TEXT = 'Please select a date';
export const DATE_IN_FUTURE_ERROR_TEXT = 'Date can not be in the future';
export const START_DATE_IN_FUTURE_ERROR_TEXT =
  'Start date can not be in the future';
export const END_DATE_IN_FUTURE_ERROR_TEXT =
  'End date can not be in the future';
export const INVALID_DATE_FORMAT_ERROR_TEXT =
  'Date Format: Day/Month/Year hh:mm:ss';
export const DATE_IS_BEFORE_MINIMAL_DATE =
  'Date should not be before 01/01/1900';
export const START_DATE_IS_BEFORE_MINIMAL_DATE =
  'Start date should not be before 01/01/1900';
export const END_DATE_IS_BEFORE_MINIMAL_DATE =
  'End date should not be before 01/01/1900';

export const containerTitleToName = {
  Accuracy: 'Select all feed types',
  'Feed height': 'Select all fences',
};
export const GOOGLE_ANALYTICS_DATA_EXPLORER_APPLY = 'dataexplorer apply';
export const GOOGLE_ANALYTICS_DATA_EXPLORER_LEGEND = 'dataexplorer legend';
export const GOOGLE_ANALYTICS_DATA_EXPLORER_DOTS = 'dataexplorer dots chart';
export const GOOGLE_ANALYTICS_DATA_EXPLORER_GRID = 'dataexplorer grid chart';
export const GOOGLE_ANALYTICS_LEGEND_ON = 'legend turned on';
export const GOOGLE_ANALYTICS_LEGEND_OFF = 'legend turned off';
export const GOOGLE_ANALYTICS_DOTS_ON = 'gots turned on';
export const GOOGLE_ANALYTICS_DOTS_OFF = 'dots turned off';
export const GOOGLE_ANALYTICS_GRID_ON = 'grid turned on';
export const GOOGLE_ANALYTICS_GRID_OFF = 'grid turned off';

// Regex get all ",\,{},[],<,>,
export const jsonRegex = /["\\{}\<>\[\]]+/gm;

export const ASTRONAUT_EVENTS_CHART_CSS_CLASS_PREFIX = 'bb-events-chart-';
export const MILKING_STATE = 'Milking';
export const FEED_GRABBER_SCANNING_COLOR = '#83D0FF';
export const FEED_GRABBER_GRABBING_COLOR = {
  'Normal grab': '#0F92E7',
  'Calibration grab': '#D4A008',
  'Cold grab': '#002749',
  Unknown: '#0F92E7',
};
export const FEED_GRABBER_DUMPING_COLOR = '#00539A';
export const FEED_GRABBER_CALIBRATION_COLOR = '#FABE1F';

export const CREATE_BACKUP_ERROR_MESSAGE =
  'Backup Setting failed to be created!';

export const TROUBLESHOOTER_POPUP_TITLE = 'Troubleshooter';

export const ALARM_SEARCH_TOOLTIP_MESSAGE = `Filter through the event description by adding a keyword, or filter in a specific column by using [column title]:[keyword], e.g. ID:134.`;
export const VECTOR_ALARM_SEARCH_TOOLTIP_MESSAGE = `Filter through the event description by adding a keyword, or filter in a specific column by using [column title]:[keyword], e.g. ID:0x1608.`;
export const EMPTY_CHECK_BOX_LEVELS_ERROR_MESSAGE =
  'You are not allowed to search without any levels!';

export const LEVEL_DROPDOWN_TOOLTIP =
  'Filter shows all values from selected level';

export const EMPTY_PROPERTY_SELECT = 'empty_property_select';
export const SELECTED_MORE_THAN_TWO_UNITS = 'selected_more_than_two_units';
export const SELECT_PROPERTY_METRIC_ERROR =
  'Graphs with more than 2 units selected in the same section.';
export const PAGES_WITH_CONSTANT_COW_LOADER = [
  'events',
  'alarms',
  'indications',
];
export const KPI_BOX_TIME_NAME = 'Box Time';

export const CANBUS_STATUS_GRAY = 'UNKNOWN';
export const CANBUS_STATUS_RED = [
  'NETWORK_DOWN',
  'SLEEPING',
  'STOPPED',
  'BUSOFF',
];
export const CANBUS_STATUS_YELLOW = ['PASSIVE'];
export const CANBUS_STATUS_GREEN = ['WARNING', 'ACTIVE'];
// PIE CHART COLORS
export const PIE_CHART_ALL_COLORS = [
  '#005C5C',
  '#0F92E7',
  '#491D8B',
  '#A83F00',
  '#00539A',
  '#FE7DB5',
  '#D4A008',
  '#009D9A',
  '#002749',
  '#602803',
  '#A56EFF',
  '#7D5B00',
  '#9E1752',
];

// inner array [0] - successful, [1] - unsuccessful
export const STORAGE_PLACE_COLORS = [
  { main: '#F45643', secondary: '#FDB6A7' },
  { main: '#005C5C', secondary: '#009D9A' },
  { main: '#0F92E7', secondary: '#83D0FF' },
  { main: '#491D8B', secondary: '#782EEB' },
  { main: '#00712A', secondary: '#43BF66' },
  { main: '#500800', secondary: '#A01F18' },
  { main: '#9E1752', secondary: '#ED5295' },
  { main: '#003A6D', secondary: '#0064B3' },
  { main: '#ED5295', secondary: '#FFB0D3' },
  { main: '#B28600', secondary: '#FABE1F' },
  { main: '#022D0D', secondary: '#0D5F26' },
  { main: '#009D9A', secondary: '#3EDBD9' },
  { main: '#803000', secondary: '#EA6300' },
  { main: '#A56EFF', secondary: '#D4BBFF' },
  { main: '#4A3601', secondary: '#7D5B00' },
  { main: '#646060', secondary: '#ACA7A7' },
];

export const UNIT_METRIC = 'Metric';
export const UNIT_IMPERIAL = 'Imperial';
// Local storage keys
export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  FARM_ID: 'farm_id',
};
export const ADVANCED_DROPDOWN_TOOLTIP = 'Advanced options';
export const BASICS_DROPDOWN_TOOLTIP = 'Basics options';
export const VECTOR_KPI_DROPDOWN_TOOLTIP = 'Vector KPIs';
export const ENGINEERING_DROPDOWN_TOOLTIP = 'Engineering';

export const DEBOUNCE_TIME_FOR_HOVER_MS = 50;
export const DEFAULT_CANVAS_CLASS = 'chart-layer-canvas';
export const CRITICAL_STATE = 'Critical';
export const CRITICAL_ALARM_STATE = 'Critical alarm';
export const NON_CRITICAL_STATE = 'Non-critical alarm';
export const OUT_OF_OPERATION_STATE = 'Out of operation';
export const IN_OPERATION_STATE = 'In operation';
export const PREFERRED_UNIT = 'preferredUnit';
export const RELOAD_ON_ERROR_COUNTER = 'reloadOnErrorCounter';

export const layoutConstants = {
  [Robots.Vector]: {
    LEFT_SIDE: 32,
    RIGHT_SIDE: 25,
  },
  [Robots.Astronaut]: {
    Y_AXIS_WIDTH: 36,
    TICKS_HEIGHT: 40,
    DEFAULT_TOOLTIP_WIDTH_IN_PX: 220,
  },
};

export const TOOLTIP_MIN_HEIGHT = 30;
export const ADE_ONLINE_UPDATES_FEATURE_FLAG = 'ade_online_updates';
export const BACKUP_AND_RESTORE = 'backup_and_restore';
export const BACKUP_VERSION_FF = 'backup_version';
export const DIAGNOSTIC_PAGE = 'diagnostic_page';
export const ALARM_CARDS_FF = 'alarm_cards';
export const DISCOVERY_LOGS_FF = 'discovery_collector_logs';

export const MAX_SUPPORTED_SCREEN_HEIGHT = 1440;
export const MAX_SUPPORTED_SCREEN_WIDTH = 2560;
export const MIN_SUPPORTED_SCREEN_HEIGHT = 768;
export const MIN_SUPPORTED_SCREEN_WIDTH = 1366;

export const TRIGGER_ZOOM_EVENT = 'triggerZoom';
export const TRIGGER_AGGREGATION_EVENT = 'moveAggregationContainer';
export const SCROLL_CONTAINERS_EVENT = 'scrollSubscribedContainers';

export const filtersByTable: FiltersByTableType = {
  farmsTable: ['Basics', 'Advanced', "Vector KPI's last 24h", 'Engineering'],
  AMFSettingsTable: ['Basics', 'Advanced'],
  settingsTable: ['Basics', 'Advanced'],
};

export const CLICKABLE_ACTIVITIES = [
  'Successful milking',
  'Failed milking',
  'Cluster cleaning',
  'Successful cluster cleaning',
  'Failed cluster cleaning',
  'Local cleaning',
  'Successful local cleaning',
  'Failed local cleaning',
];

// local storage and url keys
export const vectorStartDate = 'startDate';
export const vectorEndDate = 'endDate';
export const vectorOffEvents = 'offEvents';
export const expandedSectors = 'expandedSectors';
export const performanceIndicators = 'performanceIndicators';

export const DISABLED_PROPERTY_TOOLTIP =
  'This data is only available for hour range';

export const DEFAULT_SUPPORTED_SCREEN_WIDTH = 1920;

export const VECTOR_GRAPH_ELEMENTS = ['vGrid', 'hGrid', 'dots', 'lines'];

export const AMF_MENU_OPTIONS = [
  {
    menuTitle: 'Settings',
    iconClass: 'lely-icon-settings',
    appendixUrl: 'amf/settings',
  },
];

export const DISCOVERY_MENU_OPTIONS = [
  {
    menuTitle: 'Logs',
    iconClass: 'lely-icon-table',
    appendixUrl: 'logs',
  },
];

export const TABLE_ROW_CLICK_THRESHOLD = 5;

export const FAILED_MILKING = 'Failed milking';
export const MAX_LENGTH_SEARCH_CHARACTER = 300;

export const MAX_CONNECTION_ATTEMPTS = 10;
export const MIN_CONNECTION_ATTEMPTS = 1;
