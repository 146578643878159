import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Farm, VectorKpi } from '../components/FarmsTable/types';
import { RootState } from './rootReducer';

export interface FarmsInitialState {
  farms: Farm[] | undefined;
  isError: any;
  kpisLoading: boolean;
  kpisLoaded: boolean;
  errorMessage: string;
  isFarmsError: boolean;
}

export const farmsInitialState: FarmsInitialState = {
  farms: undefined,
  isError: false,
  kpisLoading: false,
  kpisLoaded: false,
  errorMessage: '',
  isFarmsError: false,
};

export const farmsSlice = createSlice({
  name: 'farms',
  initialState: farmsInitialState,
  reducers: {
    setFarms: (state, action) => {
      state.errorMessage = '';
      state.isFarmsError = false;
      state.farms = action.payload;
    },
    setIsFarmError: (state, action: PayloadAction<string>) => {
      state.isFarmsError = true;
      state.errorMessage = action.payload;
    },
    setKpisLoading: (state, action: PayloadAction<boolean>) => {
      state.kpisLoading = action.payload;
    },
    setKpisLoaded: (state, action: PayloadAction<boolean>) => {
      state.kpisLoaded = action.payload;
    },
    updateFarmsWithKpis: (
      state,
      action: PayloadAction<
        { id: string; dailyVectorKpi: VectorKpi[]; version: string }[]
      >,
    ) => {
      if (state.farms) {
        state.farms = state.farms.map((farm) => {
          const kpiData = action.payload.find((kpi) => kpi.id === farm.id);
          if (kpiData) {
            // Transform KPI data into flattened properties
            const flattenedKpis = kpiData.dailyVectorKpi.reduce((acc, item) => {
              acc[
                item.kpiName.charAt(0).toLowerCase() +
                  item.kpiName.slice(1).replace(/\./g, '').replace(/ /g, '')
              ] = `${item.value}`;
              return acc;
            }, {});

            return {
              ...farm,
              accuracy: '',
              freetime: '',
              durationalarmstate: '',
              nrloadingoccurences: '',
              loadedweight: '',
              nrofalarms: '',
              version: kpiData.version ?? '',
              ...flattenedKpis,
            };
          }
          return {
            ...farm,
            accuracy: '',
            freeTime: '',
            durationalarmstate: '',
            nrloadingoccurences: '',
            loadedweight: '',
            nrofalarms: '',
            version: '',
          };
        });
      }
    },
  },
});

export const getFarms = createAction('GET_FARMS_REQUEST');
export const getKpis = createAction('FETCH_KPIS_REQUEST');

export const {
  setFarms,
  setIsFarmError,
  setKpisLoading,
  setKpisLoaded,
  updateFarmsWithKpis,
} = farmsSlice.actions;

// selectors
export const farmsSelectors = {
  selectFarms: (state: RootState) => state.farms.farms,
  selectIsFarmsError: (state: RootState) => state.farms.isFarmsError,
  selectErrorMessage: (state: RootState) => state.farms.errorMessage,
};

export default farmsSlice.reducer;
