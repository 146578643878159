import {
  UPDATE_DATA_CHART_REQUESTED,
  DATA_KEY_CHARTS_DATA_RECEIVED,
  DATA_KEY_CHARTS_DATA_ERROR,
  BRUSH_ON_BOTTOM_CHART_DETECTED,
  RESET_DOMAIN,
  RESET_CHARTS_DATA,
  WHEEL_ZOOM_ONGOING_STATUS_CHANGE,
} from '../types/dataChartTypes';

// /** Create action to request update of all of the charts in the data charts screen
//  * @param {String} entityId - Id of selected entity
//  * @param {string} startDate - Start date
//  * @param {string} endDate - End date
//  * @param {string} [linkedEntityId] - Linked Astronaut entity id date
//  * @param {boolean} [replace] - Whether to replace the current URL or not
//  * @param {any} [history] - history object to navigate with react router
//  * @return {Object} - Action object
//  */
export function makeDataChartUpdateRequest(
  entityId,
  startDate,
  endDate,
  linkedEntityId,
  replace,
  navigate,
) {
  return {
    type: UPDATE_DATA_CHART_REQUESTED,
    payload: {
      entityId,
      startDate,
      endDate,
      linkedEntityId,
      replace,
      navigate,
    },
  };
}

/** Create action which to allow to put data for specific chart and key in the redux store
 * @param {Object} chartData - data for the respective data key that should be displayed in a chart
 * @param {Date} startDate - start date of the range for which the received data is about
 * @param {Date} endDate - end date of the range for which the received data is about
 * @param {String} chartId - the chart for which the user just received information for
 * @param {String} linkedEntityId - the linked entity ID which we might need in order to get the right data for charts
 * @return {Object} - Action object
 */
export function getDataKeyChartDataSuccess(
  chartData,
  startDate,
  endDate,
  chartId,
  linkedEntityId,
) {
  return {
    type: DATA_KEY_CHARTS_DATA_RECEIVED,
    payload: {
      chartData,
      startDate,
      endDate,
      chartId,
      linkedEntityId,
    },
  };
}

/** Create action which get errorMessage and set it to Redux store.
 * @param {Object} errorMessage - Error message
 * @return {Object} Return type and errorMessage
 */
export function getDataKeyChartDataError(errorMessage) {
  return {
    type: DATA_KEY_CHARTS_DATA_ERROR,
    payload: errorMessage,
  };
}

/** Create action for brush event on the bottom chart.
 * Used in order to update not only the middle chart zoom, but also the top chart's.
 * @param {Object} domainObject - The new domain object (each brush event has one associated with it)
 * @return {Object} Action object
 */
export function brushOnBottomChartDetected(domainObject) {
  return {
    type: BRUSH_ON_BOTTOM_CHART_DETECTED,
    payload: domainObject,
  };
}

/** Denote that wheel zoom operation is currently ongoing
 * @param {Boolean} isWheelZoomOngoing - Whether there is a wheel zoom operation ongoing at the moment
 * @return {Object} Action object
 */
export function changeWheelZoomStatus(isWheelZoomOngoing) {
  return {
    type: WHEEL_ZOOM_ONGOING_STATUS_CHANGE,
    payload: {
      isWheelZoomOngoing,
    },
  };
}

/** Create action for resetting the selected zoom domain
 * Used to reset the zoom of all charts when new data is received from the backend
 * @return {Object} Action object
 */
export function resetZoomDomain() {
  return {
    type: RESET_DOMAIN,
    payload: {},
  };
}

/** Create action for resetting the data for all
 * charts in the Data Explorer screen
 * We would use this when we are about to
 * fetch new data from the backend
 * in order to avoid showing to users new data in
 * one chart and old data in another
 * @return {Object} Action object
 */
export function resetChartsData() {
  return {
    type: RESET_CHARTS_DATA,
    payload: {},
  };
}
