import moment from 'moment';
import { add, isSameDay, isWithinInterval } from 'date-fns';
import type {
  FeedFencesData,
  FeedFencesKpiKeys,
  FeedFencesYesterdayAndAverageType,
  FeedTypeData,
  FeedTypeYesterdayAndAverageType,
  FeedTypesKPIKeys,
  KPIDataType,
  KPIDropdownKeys,
  KPIYesterdayAndAverageType,
  KpiSummationObjectType,
  KpiUnit,
  NestedKPIsKeys,
  RationWeightData,
  RationsData,
  RationsKPIKeys,
  RationsWeightKPIKeys,
  RationsYesterdayAndAverageType,
  StructuredKPIs,
  WeightsByNameYesterdayAndAverageType,
} from '../../reducers/types/kpiDataTypes';
import { Nullable } from '../../common/types';
import { convertUnit, metricToImperialUnits } from '../Vector/util';

export const DOM_EVENTS = {
  ZOOM_FROM_TIMELINE: 'zoomFromTimeline',
  ZOOM_FROM_KPI_CHART: 'zoomFromKpiChart',
  SET_TIMELINE_WIDTH: 'setTimelineWidth',
};

export const getUnitFromId = (id: string) => {
  if (id.includes('_kg')) return 'kg';
  // if (id.includes('_ms') || isSpecialSummableKPI(id)) return 'hh:mm:ss';
  if (id.includes('_ms')) return 'hh:mm:ss';
  if (id === 'LoadKg') {
    return 'kg';
  }
  if (id === 'LoadingSpeed') {
    return 'kg/h';
  }
  if (
    id.toLowerCase().includes('accuracy') ||
    id.toLowerCase().includes('free_time')
  )
    return '%';
  return '';
};

export function convertTime(milliseconds) {
  const duration = moment.duration(milliseconds);
  const hours = duration.hours().toString().padStart(2, '0');
  const minutes = duration.minutes().toString().padStart(2, '0');
  const seconds = duration.seconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

export const getResultBasedOnUnit = (
  unit: KpiUnit,
  value: number | undefined,
  metricPreference: string,
) => {
  if (value === undefined || value === null) {
    return '-';
  }

  switch (unit) {
    case 'kg':
      return metricToImperialUnits(
        value.toFixed(2),
        'kg',
        metricPreference,
        true,
      );
    case 'kg/h':
      return metricToImperialUnits(
        value.toFixed(2),
        'kg/h',
        metricPreference,
        true,
      );
    case 'hh:mm:ss':
      return convertTime(value);
    case '%':
      return `${value.toFixed(2)}%`;
    default:
      return value.toFixed(2);
  }
};

export function isKPIKey(key: keyof StructuredKPIs): key is KPIDropdownKeys {
  return key !== 'Feed types' && key !== 'Rations' && key !== 'Feed fences';
}

export const fillInitialFeedTypeObject = (
  feedTypeRow: FeedTypeData,
  yesterdayValue,
): Record<FeedTypesKPIKeys, KpiSummationObjectType> => {
  return {
    accuracy: {
      sum: feedTypeRow.accuracy,
      occurences: 1,
      yesterday: yesterdayValue ? yesterdayValue?.accuracy : undefined,
    },
    dumpCount: {
      sum: feedTypeRow.dumpCount,
      occurences: 1,
      yesterday: yesterdayValue ? yesterdayValue?.dumpCount : undefined,
    },
    loadedWeight: {
      sum: feedTypeRow.loadedWeight,
      occurences: 1,
      yesterday: yesterdayValue ? yesterdayValue?.loadedWeight : undefined,
    },
    loadingSpeed: {
      sum: feedTypeRow.loadingSpeed,
      occurences: 1,
      yesterday: yesterdayValue ? yesterdayValue?.loadingSpeed : undefined,
    },
  };
};

export const fillInitialRationObject = (
  rationRow: RationsData,
  isYesterday: boolean,
): Record<RationsKPIKeys, KpiSummationObjectType> => {
  return {
    accuracy: {
      sum: rationRow.accuracy,
      occurences: 1,
      yesterday: isYesterday ? rationRow.accuracy : undefined,
    },
    loadingSpeed: {
      sum: rationRow.loadingSpeed,
      occurences: 1,
      yesterday: isYesterday ? rationRow.loadingSpeed : undefined,
    },
  };
};

export const fillInitialRationWeightObject = (
  rationWeightRow: RationWeightData,
  isYesterday: boolean,
): Record<RationsWeightKPIKeys, KpiSummationObjectType> => {
  return {
    loadedWeight: {
      sum: rationWeightRow.loadedWeight,
      occurences: 1,
      yesterday: isYesterday ? rationWeightRow.loadedWeight : undefined,
    },
    rationWeight: {
      sum: rationWeightRow.rationWeight,
      occurences: 1,
      yesterday: isYesterday ? rationWeightRow.rationWeight : undefined,
    },
  };
};

export const fillInitialFeedFenceObject = (
  feedFenceRow: FeedFencesData,
  isYesterday: boolean,
): Record<FeedFencesKpiKeys, KpiSummationObjectType> => {
  return {
    avgFeedHeight: {
      sum: feedFenceRow.avgFeedHeight,
      occurences: 1,
      yesterday: isYesterday ? feedFenceRow.avgFeedHeight : undefined,
    },

    feedCount: {
      sum: feedFenceRow.feedCount,
      occurences: 1,
      yesterday: isYesterday ? feedFenceRow.feedCount : undefined,
    },
    maxFeedHeight: {
      sum: feedFenceRow.maxFeedHeight,
      occurences: 1,
      yesterday: isYesterday ? feedFenceRow.maxFeedHeight : undefined,
    },
    minFeedHeight: {
      sum: feedFenceRow.minFeedHeight,
      occurences: 1,
      yesterday: isYesterday ? feedFenceRow.minFeedHeight : undefined,
    },
  };
};

export const formatKPI = (
  kpiData: KPIDataType[],
  menu: StructuredKPIs,
  domain: Nullable<Date[]>,
  defaultDomain,
): Nullable<KPIYesterdayAndAverageType> => {
  if (!domain || !defaultDomain) {
    return null;
  }

  const endDate = defaultDomain[1];

  const finalObject: KPIYesterdayAndAverageType = {};
  Object.entries(menu).forEach(([key, value]) => {
    if (isKPIKey(key as keyof StructuredKPIs)) {
      const result = {};
      const keys = Object.keys(value as { [key: string]: string });

      const avgValuesById: Record<string, number | undefined> = {};
      const yesterdayById: Record<string, number | undefined> = {};

      Object.values(keys)
        .flat()
        .forEach((id) => {
          let valueById = 0;
          let occurrences = 0;

          kpiData?.forEach((data) => {
            const isYesterday = isSameDay(new Date(data.date), endDate);
            if (isYesterday && data.general) {
              yesterdayById[id] = data.general[id];
            }
            if (
              isWithinInterval(new Date(data.date), {
                start: domain[0],
                end: add(domain[1], { days: 1 }),
              })
            ) {
              if (data.general && id in data.general) {
                occurrences += 1;
                valueById += data.general[id];
              }
            }
          });

          avgValuesById[id] =
            occurrences === 0 ? undefined : valueById / occurrences;
        });

      result[key] = [];

      Object.entries(avgValuesById).forEach(([id, avgValue]) => {
        result[key].push({
          id,
          title: menu?.[key][id],
          average: avgValue,
          yesterday: yesterdayById[id],
          unit: getUnitFromId(id),
        });
      });

      finalObject[key] = result[key];
    }
  });

  return finalObject;
};

export const formatRationWeightsByName = (
  kpiData: KPIDataType[],
  menu: StructuredKPIs,
  domain: Nullable<Date[]>,
): Nullable<WeightsByNameYesterdayAndAverageType> => {
  if (!domain) {
    return null;
  }

  const rationNameWithWeights: Record<
    string,
    Record<string, Record<RationsWeightKPIKeys, KpiSummationObjectType>>
  > = {};

  kpiData.forEach((kpiDataItem) => {
    if (kpiDataItem.rationData) {
      kpiDataItem.rationData.forEach((rationItem) => {
        rationItem.weights?.forEach((weight) => {
          if (
            isWithinInterval(new Date(kpiDataItem.date), {
              start: domain[0],
              end: add(domain[1], { days: 1 }),
            })
          ) {
            const isYesterday = isSameDay(
              new Date(kpiDataItem.date),
              domain[1],
            );

            if (!rationNameWithWeights[rationItem.rationName]) {
              rationNameWithWeights[rationItem.rationName] = {};
            }

            const feedObject =
              rationNameWithWeights[rationItem.rationName][weight.feedName];
            if (!feedObject) {
              rationNameWithWeights[rationItem.rationName][weight.feedName] =
                fillInitialRationWeightObject(weight, isYesterday);
            } else {
              Object.keys(feedObject).forEach((key) => {
                feedObject[key].sum += weight[key];
                feedObject[key].occurences += 1;

                if (isYesterday) {
                  feedObject[key].yesterday = weight[key];
                }
              });
            }
          }
        });
      });
    }
  });

  const result: WeightsByNameYesterdayAndAverageType = {};

  Object.keys(rationNameWithWeights).forEach((rationName) => {
    result[rationName] = {};

    Object.keys(rationNameWithWeights[rationName]).forEach((feedName) => {
      const currentObj = rationNameWithWeights[rationName][feedName];

      result[rationName][feedName] = {
        loadedWeight: {
          average:
            currentObj.loadedWeight.sum / currentObj.loadedWeight.occurences,
          yesterday: currentObj.loadedWeight.yesterday!,
          title: menu.Rations!['weights'].loadedWeight,
          unit: 'kg',
          id: `${rationName}_${feedName}_loadedWeight`,
        },
        rationWeight: {
          average:
            currentObj.rationWeight.sum / currentObj.rationWeight.occurences,
          yesterday: currentObj.rationWeight.yesterday!,
          title: menu.Rations!['weights'].rationWeight,
          unit: 'kg',
          id: `${rationName}_${feedName}_rationWeight`,
        },
      };
    });
  });

  return result;
};

export const formatRationKPI = (
  kpiData: KPIDataType[],
  menu: StructuredKPIs,
  domain: Nullable<Date[]>,
  defaultDomain: Nullable<Date[]>,
) => {
  if (!domain || !defaultDomain) {
    return null;
  }

  const result = getStructuredDataWithinInterval(
    kpiData,
    domain,
    'rationData',
    fillInitialRationObject,
    'rationName',
    defaultDomain,
  );

  const finalObject: RationsYesterdayAndAverageType = {};

  Object.keys(result).forEach((rationName) => {
    const currentObj = result[rationName];

    finalObject[rationName] = {
      accuracy: {
        average: currentObj.accuracy.sum / currentObj.accuracy.occurences,
        yesterday: currentObj.accuracy.yesterday!,
        title: menu['Rations']!.accuracy,
        unit: '%',
        id: `${rationName}_accuracy`,
      },
      loadingSpeed: {
        average:
          currentObj.loadingSpeed.sum / currentObj.loadingSpeed.occurences,
        yesterday: currentObj.loadingSpeed.yesterday!,
        title: menu['Rations']!.loadingSpeed,
        unit: 'kg/h',
        id: `${rationName}_loadingSpeed`,
      },
    };
  });

  return finalObject;
};

export const formatFeedFencesKPI = (
  kpiData: KPIDataType[],
  menu: StructuredKPIs,
  domain: Nullable<Date[]>,
  defaultDomain: Nullable<Date[]>,
) => {
  if (!domain || !defaultDomain) {
    return null;
  }

  const result = getStructuredDataWithinInterval(
    kpiData,
    domain,
    'feedFencesData',
    fillInitialFeedFenceObject,
    'fenceName',
    defaultDomain,
  );

  const finalObject: FeedFencesYesterdayAndAverageType = {};

  Object.keys(result).forEach((feedFenceName) => {
    const currentObj = result[feedFenceName];

    finalObject[feedFenceName] = {
      avgFeedHeight: {
        average:
          currentObj.avgFeedHeight.sum / currentObj.avgFeedHeight.occurences,
        yesterday: currentObj.avgFeedHeight.yesterday!,
        title: menu['Feed fences']!.avgFeedHeight,
        unit: 'mm',
        id: `${feedFenceName}_avgFeedHeight`,
      },
      feedCount: {
        average: currentObj.feedCount.sum / currentObj.feedCount.occurences,
        yesterday: currentObj.feedCount.yesterday!,
        title: menu['Feed fences']!.feedCount,
        unit: '',
        id: `${feedFenceName}_feedCount`,
      },
      maxFeedHeight: {
        average:
          currentObj.maxFeedHeight.sum / currentObj.maxFeedHeight.occurences,
        yesterday: currentObj.maxFeedHeight.yesterday!,
        title: menu['Feed fences']!.maxFeedHeight,
        unit: 'mm',
        id: `${feedFenceName}_maxFeedHeight`,
      },
      minFeedHeight: {
        average:
          currentObj.minFeedHeight.sum / currentObj.minFeedHeight.occurences,
        yesterday: currentObj.minFeedHeight.yesterday!,
        title: menu['Feed fences']!.minFeedHeight,
        unit: 'mm',
        id: `${feedFenceName}_minFeedHeight`,
      },
    };
  });

  return finalObject;
};

export const formatFeedTypeKPI = (
  kpiData: KPIDataType[],
  menu: StructuredKPIs,
  domain: Nullable<Date[]>,
  defaultDomain: Nullable<Date[]>,
): Nullable<FeedTypeYesterdayAndAverageType> => {
  if (!domain || !defaultDomain) {
    return null;
  }

  const result = getStructuredDataWithinInterval(
    kpiData,
    domain,
    'feedTypeData',
    fillInitialFeedTypeObject,
    'feedName',
    defaultDomain,
  );

  const finalObject: FeedTypeYesterdayAndAverageType = {};
  Object.keys(result).forEach((feedName) => {
    const currentObj = result[feedName];

    finalObject[feedName] = {
      accuracy: {
        average: currentObj.accuracy.sum / currentObj.accuracy.occurences,
        yesterday: currentObj.accuracy.yesterday!,
        title: menu['Feed types']!.accuracy,
        unit: '%',
        id: `${feedName}_accuracy`,
      },
      dumpCount: {
        average: currentObj.dumpCount.sum / currentObj.dumpCount.occurences,
        yesterday: currentObj.dumpCount.yesterday!,
        title: menu['Feed types']!.dumpCount,
        unit: '',
        id: `${feedName}_dumpCount`,
      },
      loadedWeight: {
        average:
          currentObj.loadedWeight.sum / currentObj.loadedWeight.occurences,
        yesterday: currentObj.loadedWeight.yesterday!,
        title: menu['Feed types']!.loadedWeight,
        unit: 'kg',
        id: `${feedName}_loadedWeight`,
      },
      loadingSpeed: {
        average:
          currentObj.loadingSpeed.sum / currentObj.loadingSpeed.occurences,
        yesterday: currentObj.loadingSpeed.yesterday!,
        title: menu['Feed types']!.loadingSpeed,
        unit: 'kg/h',
        id: `${feedName}_loadingSpeed`,
      },
    };
  });

  return finalObject;
};

export const unitInTitle = (title, metricPreference) => {
  if (title.includes('(kg/h)')) {
    const index = title.indexOf('(kg/h)');
    const titleWithoutUnit = title.slice(0, index);
    const convertedUnit = convertUnit('kg/h', metricPreference);
    return `${titleWithoutUnit} (${convertedUnit})`;
  }
  if (title.includes('kg')) {
    const index = title.indexOf('(kg)');
    const titleWithoutUnit = title.slice(0, index);
    const convertedUnit = convertUnit('kg', metricPreference);
    return `${titleWithoutUnit} (${convertedUnit})`;
  }
  if (title.includes('mm')) {
    const index = title.indexOf('(mm)');
    const titleWithoutUnit = title.slice(0, index);
    const convertedUnit = convertUnit('mm', metricPreference);
    return `${titleWithoutUnit} (${convertedUnit})`;
  }
  return title;
};

function getStructuredDataWithinInterval<
  K extends NestedKPIsKeys,
  T extends keyof Omit<KPIDataType, 'date' | 'general'>,
>(
  kpiData: KPIDataType[],
  domain: Date[],
  kpiDataProperty: T,
  initialObjectFunction: any,
  nameKey: string,
  defaultDomain: Date[],
) {
  const result: Record<string, Record<K, KpiSummationObjectType>> = {};
  const yesterdayValue = kpiData.filter((x) => {
    if (isSameDay(new Date(x.date), defaultDomain[1])) {
      return x;
    }
    return null;
  });

  kpiData.forEach((dataItem) => {
    dataItem[kpiDataProperty]?.forEach((dataTypeRow) => {
      if (
        isWithinInterval(new Date(dataItem.date), {
          start: domain[0],
          end: add(domain[1], { days: 1 }),
        })
      ) {
        if (!result[dataTypeRow[nameKey]]) {
          result[dataTypeRow[nameKey]] = initialObjectFunction(
            dataTypeRow,
            isSameDay(new Date(dataItem.date), defaultDomain[1]),
          );
        } else {
          const currentObj = result[dataTypeRow[nameKey]];
          Object.keys(currentObj).forEach((key) => {
            currentObj[key].sum += dataTypeRow[key];
            currentObj[key].occurences += 1;
            const yesterdayValueOfProperty = (
              yesterdayValue[0]?.[kpiDataProperty] as Record<string, unknown>[]
            )?.filter((x) => {
              return x[nameKey] === dataTypeRow[nameKey];
            });
            currentObj[key].yesterday =
              yesterdayValueOfProperty?.[0]?.[key] || undefined;
          });
          result[dataTypeRow[nameKey]] = currentObj;
        }
      }
    });
  });

  return result;
}
