import { GET_FARM_ENTITIES_SERVICE_URL } from '../api/lelyBackend/config';
import { requestDictionary } from '../common/requestUtil';
import {
  GET_FARM_DETAILS_REQUEST_SUCCESS,
  GET_FARM_DETAILS_REQUEST_ERROR,
  SET_CURRENT_SELECTED_ENTITY,
  RESET_FARM_DETAILS,
} from '../components/FarmDetails/types';

/** Store states
 * @constant {Object} */
export const initialState = {
  farmDetails: {},
  isFarmDetailsError: false,
  currentSelectedEntityDetails: {},
  errorMessage: '',
};

/** Farm Details Reducer.
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function farmDetailsReducer(state = initialState, action = {}) {
  switch (action.type) {
    /** Action Creator - Set entity details to store */
    case SET_CURRENT_SELECTED_ENTITY:
      return {
        ...state,
        currentSelectedEntityDetails: action.payload,
      };

    /** Action Creator - Set farm details to store
     * if API request passed successfully */
    case GET_FARM_DETAILS_REQUEST_SUCCESS:
      if (
        action.payload.requestUuid &&
        requestDictionary[GET_FARM_ENTITIES_SERVICE_URL]
      ) {
        if (
          action.payload.requestUuid !==
          requestDictionary[GET_FARM_ENTITIES_SERVICE_URL]
        ) {
          // ignore this request; it's not the last one of this type that was made
          return { ...state }; // this effectively ignoring the request/returning the same state as before
        }
      }
      return {
        ...state,
        farmDetails: action.payload,
        isFarmDetailsError: false,
        errorMessage: '',
      };

    case RESET_FARM_DETAILS:
      return {
        ...state,
        farmDetails: {},
        isFarmDetailsError: false,
        errorMessage: '',
      };

    /** Action Creator - Show error message if
     * request ot API failed */
    case GET_FARM_DETAILS_REQUEST_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        farmDetails: {},
        isFarmDetailsError: true,
      };
    default:
      return state;
  }
}

export default farmDetailsReducer;
