/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import Preloader from '../Preloader/Preloader';

// Static flag to prevent multiple redirects across remounts
let isHandlingRedirect = false;
let redirectAttempts = 0;
const MAX_ATTEMPTS = 3;

function AuthRedirect() {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(true);

  console.log('AuthRedirect component mounted');

  useEffect(() => {
    console.log('AuthRedirect useEffect triggered', {
      isProcessing,
      isHandlingRedirect,
    });

    const handleRedirect = async () => {
      // Prevent multiple concurrent redirect handling
      if (isHandlingRedirect) {
        console.log('Already handling redirect, skipping');
        return;
      }

      // Prevent infinite redirect loops
      if (redirectAttempts >= MAX_ATTEMPTS) {
        console.error('Max redirect attempts reached');
        navigate('/login', { replace: true });
        return;
      }

      try {
        isHandlingRedirect = true;
        redirectAttempts++;

        const response = await instance.handleRedirectPromise();

        if (response) {
          // Clear flags on successful response
          isHandlingRedirect = false;
          redirectAttempts = 0;

          const destination = localStorage.getItem('loginRedirect') || '/farms';
          localStorage.removeItem('loginRedirect');
          console.log('navigates: ', destination);
          navigate(destination, { replace: true });
        }
      } catch (err) {
        console.error('Auth redirect error:', err);
        navigate('/login', { replace: true });
      } finally {
        isHandlingRedirect = false;
        setIsProcessing(false);
      }
    };

    // Only attempt redirect handling if not already in progress
    if (!isHandlingRedirect) {
      handleRedirect();
    }

    // Cleanup function
    return () => {
      console.log('AuthRedirect cleanup');
    };
  }, [instance, navigate]);

  console.log('AuthRedirect rendering');
  return <Preloader />;
}

export default AuthRedirect;
