/* eslint-disable no-console */
import { call, put, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getModalLogNodesAction,
  setModalLogNodes,
} from '../../reducers/logsSlice';
import { LogsApiType } from '../../api/lelyBackend/logsApi';
import { AwaitedApiResultType } from '../../common/types';

export default function* logsFilterDownloadModalSaga(api: LogsApiType) {
  while (true) {
    const modalLogNodesAction: PayloadAction<{
      entityId: string;
      startDate: string;
      endDate: string;
    }> = yield take(getModalLogNodesAction.type);

    if (modalLogNodesAction.payload) {
      const { entityId, startDate, endDate } = modalLogNodesAction.payload;
      yield call(getModalLogsMenuOptions, api, entityId, startDate, endDate);
    }
  }
}

function* getModalLogsMenuOptions(
  api: LogsApiType,
  entityId: string,
  logStartDateTime: string,
  logEndDateTime: string,
) {
  try {
    const modalLogNodes: AwaitedApiResultType<typeof api.getLogNodes> =
      yield call(
        api.getModalLogNodes,
        entityId,
        logStartDateTime,
        logEndDateTime,
      );

    yield put(setModalLogNodes(modalLogNodes.data));
  } catch (error) {
    console.error('Error while fetching logs menu options:', error);
  }
}
