import React from 'react';
import { useDispatch } from 'react-redux';
import './LogoutButton.scss';
import { useMsal } from '@azure/msal-react';
import { logoutRequest } from '../../reducers/userSessionSlice';
import PrimaryButton from '../../common/components/Buttons/PrimaryButton';

/** Logout Button component */
function LogoutButton() {
  const dispatch = useDispatch();
  const { accounts, instance } = useMsal();

  const onLogoutClicked = () => {
    dispatch(logoutRequest());

    if (accounts.length > 0) {
      setTimeout(async () => {
        const currentAccount = instance.getAccountByHomeId(
          accounts[0].homeAccountId,
        );
        await instance.logoutRedirect({ account: currentAccount });
      }, 1000);
    }
  };

  return (
    <PrimaryButton onClick={onLogoutClicked} data-testid="btn-logout">
      Logout
    </PrimaryButton>
  );
}

export default LogoutButton;
