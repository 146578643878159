import { GET_ENTITY_INFO_SERVICE_URL } from '../api/lelyBackend/config';
import {
  GET_ENTITY_INFO_REQUEST_SUCCESS,
  GET_ENTITY_INFO_REQUEST_ERROR,
  RESET_ENTITY_INFO,
  RESET_FARM_NAME_IN_BREADCRUMBS_COMPONENT,
} from '../common/components/FarmAndEntityDetailsHeader/types';
import { requestDictionary } from '../common/requestUtil';
import { getUuid } from '../components/Vector/mfrchart/Util';

/** Store states
 * @constant {Object} */
export const initialState = {
  entityInfo: {},
  errorMessage: '',
};

/** Components Reducer
 * @param {Object} state - Initial state
 * @param {Object} action - Payload object
 * @return {Object} - return new state
 */
function entityInformationReducer(state = initialState, action = {}) {
  let modifiedState;
  switch (action.type) {
    /** Action Creator - Set components data to store if
     * API request passed successfully */
    case GET_ENTITY_INFO_REQUEST_SUCCESS:
      if (
        action.payload.requestUuid &&
        requestDictionary[GET_ENTITY_INFO_SERVICE_URL]
      ) {
        if (
          action.payload.requestUuid !==
          requestDictionary[GET_ENTITY_INFO_SERVICE_URL]
        ) {
          // ignore this request; it's not the last one of this type that was made
          return { ...state }; // this effectively ignoring the request/returning the same state as before
        }
      }
      return {
        ...state,
        entityInfo: action.payload.entityInfoData,
        errorMessage: '',
      };

    /** Action Creator - Set components data to store if
     * API request passed successfully */
    case GET_ENTITY_INFO_REQUEST_ERROR:
      return {
        ...state,
        componentsData: {},
        errorMessage: action.payload,
      };
    case RESET_ENTITY_INFO:
      return initialState;
    case RESET_FARM_NAME_IN_BREADCRUMBS_COMPONENT:
      modifiedState = _.cloneDeep(state);
      if (modifiedState.entityInfo) {
        modifiedState.entityInfo.farmName = '';
        modifiedState.entityInfo.name = '';
        // #region requestUtil logic
        const requestUuid = getUuid();
        requestDictionary[GET_ENTITY_INFO_SERVICE_URL] = requestUuid;
        // #endregion
      }

      return modifiedState;
    default:
      return state;
  }
}
export const selectFarmTimeZone = (state) =>
  state.entityInfo?.entityInfo?.farmTimeZone;

export default entityInformationReducer;
